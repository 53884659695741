import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import reduce from 'image-blob-reduce';
import sha256 from 'crypto-js/sha256'
import { enc } from 'crypto-js';

import firebase from '../../firebase';

import Container from '../Container';
import { useFormikContext } from 'formik';

import { dropzone } from './index.module.css';

const ROOT_STORAGE_URL = 'https://storage.googleapis.com/tacto-63a54.appspot.com/';

const reducer = reduce();
reducer._create_blob = function(env) {
  return this.pica.toBlob(env.out_canvas, 'image/png', 0.8)
    .then((blob) => {
      env.out_blob = blob;
      return env;
    });
}
const resizeImage = (blob) => reducer.toBlob(blob, { max: 180 });

const hashBlob = (blob) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.addEventListener('loadend', ()=> {
    resolve({ blob, hash: sha256(reader.result).toString(enc.Hex).slice(0, 8) });
  });
  reader.readAsDataURL(blob);
});

export default ({ destination, name, label }) => {
  const [preview, setPreview] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const formik = useFormikContext();
  const handleDrop = (files) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setPreview(reader.result);
      resizeImage(files[0])
        .then(hashBlob)
        .then(({ blob, hash }) => {
          setUploadStatus('Uploading your image...');
          const path = `${destination}${hash}.png`;
          const storageRef = firebase.storage().ref().child(path);
          storageRef.put(blob).then(() => {
            setUploadStatus('Your image uploaded successfully.')
            formik.setFieldValue(name, `${ROOT_STORAGE_URL}${path}`);
          });
        }).catch(({ message }) =>
          setUploadStatus(`There was an error uploading your image: ${message}`)
        );
    }, false);
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDropAccepted: handleDrop
  });
  
  return (
    <Container fluid vSpace>
      {label && <label htmlFor="name">{label}</label>}
      <div {...getRootProps({className: dropzone })}>
        <input {...getInputProps()} />
        { preview ? <img
          alt='Preview of uploaded profile'
          src={preview}
          /> : 'Drop an image here, or click to open a file browser. For best results, upload a square-formatted picture.'}
      </div>
      {uploadStatus && <p>{uploadStatus}</p>}
    </Container>
  );
}