import { combineReducers } from 'redux';
import * as types from './types';
import { GLOBAL_CLEAR_ERROR, GLOBAL_CLEAR_SUCCESS } from '../types';

const current = (state = {}, { type, user }) => {
  switch (type) {
    case types.ACCOUNT_CREATE_SUCCESS:
    case types.ACCOUNT_LOGIN_SUCCESS:
    case types.ACCOUNT_HYDRATE_LOGGED_IN:
    case types.ACCOUNT_RELOAD_SUCCESS:
      return {
        user: user && { ...user }
      };
    case types.ACCOUNT_HYDRATE_LOGGED_OUT:
    case types.ACCOUNT_LOGOUT_SUCCESS:
      return {};
    default: 
      return state;
  }
};

const resetEmail = (state = null, { type, email }) =>
  type === types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_SUCCESS ? email : state;

const error = (state = null, { type, code, message }) => {
  switch (type) {
    case types.ACCOUNT_CREATE_START:
    case types.ACCOUNT_LOGIN_START:
    case types.ACCOUNT_RELOAD_START:
    case types.ACCOUNT_SEND_VERIFICATION_START:
    case types.ACCOUNT_CONFIRM_VERIFICATION_START:
    case types.ACCOUNT_CONFIRM_VERIFICATION_SUCCESS:
    case types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_SUCCESS:
    case types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_SUCCESS:
    case types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_START:
    case types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_START:
    case types.ACCOUNT_RESET_PASSWORD_START:
    case types.ACCOUNT_UPDATE_PASSWORD_START:
    case GLOBAL_CLEAR_ERROR:
      return null;
    case types.ACCOUNT_LOGIN_ERROR:
    case types.ACCOUNT_CREATE_ERROR:
    case types.ACCOUNT_RELOAD_ERROR:
    case types.ACCOUNT_SEND_VERIFICATION_ERROR:
    case types.ACCOUNT_CONFIRM_VERIFICATION_ERROR:
    case types.ACCOUNT_LOGOUT_ERROR:
    case types.ACCOUNT_RESET_PASSWORD_ERROR:
    case types.ACCOUNT_UPDATE_PASSWORD_ERROR:
    case types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_ERROR:
    case types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_ERROR:
      return { code, message }
    default:
      return state;
  }
}

const successMessage = (state = null, { type, message }) => {
  switch (type) {
    case types.ACCOUNT_RESET_PASSWORD_SUCCESS:
    case types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_SUCCESS:
    case types.ACCOUNT_SEND_VERIFICATION_SUCCESS:
      return message;
    case GLOBAL_CLEAR_SUCCESS:
      return null;
    default:
      return state;
  }
}

const isWorking = (state = false, { type }) => {
  switch (type) {
    case types.ACCOUNT_CREATE_START:
    case types.ACCOUNT_LOGIN_START:
    case types.ACCOUNT_LOGOUT_START:
    case types.ACCOUNT_RELOAD_START:
    case types.ACCOUNT_SEND_VERIFICATION_START:
    case types.ACCOUNT_CONFIRM_VERIFICATION_START:
    case types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_START:
    case types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_START:
    case types.ACCOUNT_RESET_PASSWORD_START:
      return true;
    case types.ACCOUNT_SEND_VERIFICATION_SUCCESS:
    case types.ACCOUNT_SEND_VERIFICATION_ERROR:
    case types.ACCOUNT_CONFIRM_VERIFICATION_SUCCESS:
    case types.ACCOUNT_CONFIRM_VERIFICATION_ERROR:
    case types.ACCOUNT_CREATE_SUCCESS:
    case types.ACCOUNT_CREATE_ERROR:
    case types.ACCOUNT_LOGIN_SUCCESS:
    case types.ACCOUNT_LOGIN_ERROR:
    case types.ACCOUNT_LOGOUT_SUCCESS:
    case types.ACCOUNT_LOGOUT_ERROR:
    case types.ACCOUNT_RELOAD_SUCCESS:
    case types.ACCOUNT_RELOAD_ERROR:
    case types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_SUCCESS:
    case types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_ERROR:
    case types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_SUCCESS:
    case types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_ERROR:
    case types.ACCOUNT_RESET_PASSWORD_SUCCESS:
    case types.ACCOUNT_RESET_PASSWORD_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  current,
  error,
  isWorking,
  resetEmail,
  successMessage
})