import React from 'react';

export default (fillColor = '#FCFDFE') => (
    <svg width="17px" height="9px" viewBox="0 0 17 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>actions</title>
        <g id="desktop-wireframes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Dashboard---Desktop" transform="translate(-885.000000, -257.000000)" fill={fillColor} fillRule="nonzero">
                <g id="Group-2" transform="translate(643.000000, 235.000000)">
                    <g id="Group" transform="translate(242.000000, 22.000000)">
                        <path d="M1.57567164,4.275 C1.57567164,2.81295 2.72839984,1.6245 4.14650432,1.6245 L7.46370778,1.6245 L7.46370778,0 L4.14650432,0 C1.85763394,0 0,1.9152 0,4.275 C0,6.6348 1.85763394,8.55 4.14650432,8.55 L7.46370778,8.55 L7.46370778,6.9255 L4.14650432,6.9255 C2.72839984,6.9255 1.57567164,5.73705 1.57567164,4.275 Z M4.97580518,5.13 L11.6102121,5.13 L11.6102121,3.42 L4.97580518,3.42 L4.97580518,5.13 Z M12.439513,0 L9.12230951,0 L9.12230951,1.6245 L12.439513,1.6245 C13.8576174,1.6245 15.0103456,2.81295 15.0103456,4.275 C15.0103456,5.73705 13.8576174,6.9255 12.439513,6.9255 L9.12230951,6.9255 L9.12230951,8.55 L12.439513,8.55 C14.7283833,8.55 16.5860173,6.6348 16.5860173,4.275 C16.5860173,1.9152 14.7283833,0 12.439513,0 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);