import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import AddCard from '../../components/AddCard';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import isMobile from 'is-mobile';
import CtaButton from '../../components/CtaButton';


const UpdateSuccess = ({ card }) => {
  const match = useRouteMatch('/card/edit/created');
  const instructions = isMobile({ tablet: true }) ?
    "(And it'll be handy anytime you want to share it.)" :
    "Open the camera on your mobile device and point it at this QR code to scan it:";
  return (
    <Modal
      description={`Your card is ready to go. Now let's add it to your wallet so you can see what it looks like. ${instructions}`}
      title='Card created!'
      open={Boolean(match)}
    >
      { card && <AddCard link={card.adaptiveUrl} /> }
      <Container fluid vSpace>
        <CtaButton primary linkTo='/'>Take me home</CtaButton>
      </Container>
    </Modal>
  )
}

export default connect(
  ({ account, card }) => ({ card: card.data, user: account.current.user }),
)(UpdateSuccess);
