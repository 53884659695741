export const ACCOUNT_CREATE_START = 'ACCOUNT_CREATE_START';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_ERROR = 'ACCOUNT_CREATE_ERROR';

export const ACCOUNT_LOGIN_START = 'ACCOUNT_LOGIN_START';
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_ERROR = 'ACCOUNT_LOGIN_ERROR';

export const ACCOUNT_LOGOUT_START = 'ACCOUNT_LOGOUT_START';
export const ACCOUNT_LOGOUT_SUCCESS = 'ACCOUNT_LOGOUT_SUCCESS';
export const ACCOUNT_LOGOUT_ERROR = 'ACCOUNT_LOGOUT_ERROR';

export const ACCOUNT_VERIFY_PASSWORD_RESET_CODE_START = "ACCOUNT_VERIFY_PASSWORD_RESET_CODE_START";
export const ACCOUNT_VERIFY_PASSWORD_RESET_CODE_SUCCESS = "ACCOUNT_VERIFY_PASSWORD_RESET_CODE_SUCCESS";
export const ACCOUNT_VERIFY_PASSWORD_RESET_CODE_ERROR = "ACCOUNT_VERIFY_PASSWORD_RESET_CODE_ERROR";

export const ACCOUNT_SEND_PASSWORD_RESET_EMAIL_START = "ACCOUNT_SEND_PASSWORD_RESET_EMAIL_START";
export const ACCOUNT_SEND_PASSWORD_RESET_EMAIL_SUCCESS = "ACCOUNT_SEND_PASSWORD_RESET_EMAIL_SUCCESS";
export const ACCOUNT_SEND_PASSWORD_RESET_EMAIL_ERROR = "ACCOUNT_SEND_PASSWORD_RESET_EMAIL_ERROR";

export const ACCOUNT_RESET_PASSWORD_START = "ACCOUNT_RESET_PASSWORD_START";
export const ACCOUNT_RESET_PASSWORD_SUCCESS = "ACCOUNT_RESET_PASSWORD_SUCCESS";
export const ACCOUNT_RESET_PASSWORD_ERROR = "ACCOUNT_RESET_PASSWORD_ERROR";

export const ACCOUNT_UPDATE_PASSWORD_START = "ACCOUNT_UPDATE_PASSWORD_START";
export const ACCOUNT_UPDATE_PASSWORD_SUCCESS = "ACCOUNT_UPDATE_PASSWORD_SUCCESS";
export const ACCOUNT_UPDATE_PASSWORD_ERROR = "ACCOUNT_UPDATE_PASSWORD_ERROR";

export const ACCOUNT_CONFIRM_VERIFICATION_START = 'ACCOUNT_CONFIRM_VERIFICATION_START'
export const ACCOUNT_CONFIRM_VERIFICATION_SUCCESS = 'ACCOUNT_CONFIRM_VERIFICATION_SUCCESS'
export const ACCOUNT_CONFIRM_VERIFICATION_ERROR = 'ACCOUNT_CONFIRM_VERIFICATION_ERROR'

export const ACCOUNT_SEND_VERIFICATION_START = 'ACCOUNT_SEND_VERIFICATION_START';
export const ACCOUNT_SEND_VERIFICATION_SUCCESS = 'ACCOUNT_SEND_VERIFICATION_SUCCESS';
export const ACCOUNT_SEND_VERIFICATION_ERROR = 'ACCOUNT_SEND_VERIFICATION_ERROR';

export const ACCOUNT_HYDRATE_LOGGED_IN = 'ACCOUNT_HYDRATE_LOGGED_IN';
export const ACCOUNT_HYDRATE_LOGGED_OUT = 'ACCOUNT_HYDRATE_LOGGED_OUT';

export const ACCOUNT_RELOAD_START = 'ACCOUNT_RELOAD_START';
export const ACCOUNT_RELOAD_SUCCESS = 'ACCOUNT_RELOAD_SUCCESS';
export const ACCOUNT_RELOAD_ERROR = 'ACCOUNT_RELOAD_ERROR';
 