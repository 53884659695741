import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { StylesProvider } from '@material-ui/core/styles';
import BottomNav from './components/BottomNav';
import TopNav from './components/TopNav';
import ResetMessages from './components/ResetMessages';
import Dashboard from './views/Dashboard';

import AccountActions from './views/Account/Actions';
import AccountSuccess from './views/Account/Success';
import AccountSettings from './views/Account/Settings';
import CreateAccount from './views/Account/Create';
import ForgotPassword from './views/Account/ForgotPassword';
import Subscribe from './views/Account/Subscribe';
import Subscribed from './views/Account/Subscribed';
import Login from './views/Account/Login';
import SendUpdate from './views/Update/Send';
import ScrollToTop from './components/ScrollToTop';
import VerifyAccount from './views/Account/Verify';

import UpdateCard from './views/Card/Update';

import { wrapper } from './App.module.css';

function App({ user }) {
  return (
    <StylesProvider injectFirst>
      <div className={wrapper}>
        <Router>
          <TopNav />
          <ScrollToTop />
          <Switch>
            <Route path='/account/login'>
              <Login />
            </Route>
            <Route path='/account/create'>
              <CreateAccount />
            </Route>
            <Route path='/account/verify'>
              <VerifyAccount />
            </Route>
            <Route path='/account/actions'>
              <AccountActions />
            </Route>
            <Route path='/account/forgot'>
              <ForgotPassword />
            </Route>
            {user && user.emailVerified ? (
              <>
                <Route path='/account/success'>
                  <AccountSuccess />
                </Route>
                <Route path='/account/settings'>
                  <AccountSettings />
                </Route>
                <Route path='/account/subscribe'>
                  <Subscribe />
                </Route>
                <Route path='/account/subscribed'>
                  <Subscribed />
                  <Dashboard />
                </Route>
                <Route path='/card/edit'>
                  <UpdateCard />
                </Route>
                <Route path='/card/preview'>
                </Route>
                <Route path='/update/send'>
                  <SendUpdate />
                  <Dashboard />
                </Route>
                <Route exact path='/'>
                  <Dashboard />
                </Route>
              </>
            ) : user ? (
              <>
                <Redirect to='/account/verify' />
              </>
             ) : (
              <Redirect to='/account/login' />
            )}
          </Switch>
          <ResetMessages />
        </Router>
        <BottomNav />
      </div>
    </StylesProvider>
  );
}

export default connect(
  ({ account }) => ({ user: account.current.user })
)(App);
