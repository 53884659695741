import React from 'react';
import Container from '../Container';

import { buttonGroup } from './index.module.css';

const ButtonGroup = ({ children }) => (
  <Container fluid vSpace className={buttonGroup}>
    {children}
  </Container>
)

export default ButtonGroup;