import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

const configDev = {
  apiKey: "AIzaSyDYMI_ixdp5FDzmCFmXlsDNa-IRQs8Hn-M",
  authDomain: "tacto-dev.firebaseapp.com",
  databaseURL: "https://tacto-dev.firebaseio.com",
  projectId: "tacto-dev",
  storageBucket: "tacto-dev.appspot.com",
  messagingSenderId: "545265538227",
  appId: "1:545265538227:web:a2848b58e3dbde39678cc6",
  measurementId: "G-L4XN1LV8PF"
};

const configProd = {
  apiKey: "AIzaSyDq1L-pUH1BClFCin-unl8rtbytP6XiAbU",
  authDomain: "tacto-63a54.firebaseapp.com",
  databaseURL: "https://tacto-63a54.firebaseio.com",
  projectId: "tacto-63a54",
  storageBucket: "tacto-63a54.appspot.com",
  messagingSenderId: "787785176864",
  appId: "1:787785176864:web:10918ba21655a6acace945",
  measurementId: "G-36LD88YKKE"
};

export const config = process.env.REACT_APP_ENV === 'dev' ? configDev : configProd;

export const FirebaseContext = React.createContext(firebase);

export default firebase;