import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import CtaButton from '../../components/CtaButton';
import { Alert, Form, Input, Submit } from '../../components/Form';
import Modal from '../../components/Modal';

import * as cardActions from '../../redux/card/actions';
import ButtonGroup from '../../components/ButtonGroup';

const schema = Yup.object().shape({ 
  update: Yup.string()
    .required('Required.')
    .max(200, 'Character limit: 200')
});

const SendUpdate = ({ error, status, sendUpdate, user }) => {
  const history = useHistory();
  const handleSubmit = ({ update }) => {
    sendUpdate(user, update)
      .then(({ type }) => type.match(/SUCCESS$/) && history.push('/', { preserveStatus: true }));
  }
  return (
    <Modal title='Send update' open parentPath='/'>
      <p className='introText'>This will only be sent to iOS users who have your card installed.</p>
      <Form
        initialValues={{ update: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        >
        <Input multiline name='update' placeholder='Type your message here.' />
        { error && <Alert error>{error}</Alert> }
        { status && <Alert success>{status}</Alert> }
        <ButtonGroup>
          <CtaButton eventName="cancel_update" inline linkTo='/'>Cancel</CtaButton>
          <Submit inline>Send Update</Submit>
        </ButtonGroup>
      </Form>
    </Modal>
  );
}

export default connect(
  ({ account, card }) => ({ error: card.error, status: card.notificationStatus, user: account.current.user }),
  (dispatch) => ({ sendUpdate: ({ uid }, update) => dispatch(cardActions.sendNotification(uid, update)) })
)(SendUpdate)