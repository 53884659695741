import copy from 'clipboard-copy';
import * as types from './types';


// NB: These functions assume a one-to-on relationship between
// users and cards. The UI currently treats this as one-to-one,
// however this may change in the future.

export const create = (attr) => (dispatch, _, { db, logEvent }) => {
  dispatch({ type: types.CARD_CREATE_START });
  logEvent('create_card');
  return db.collection('cards').add(attr)
    .then((ref) => {
      dispatch({
        type: types.CARD_CREATE_SUCCESS,
        id: ref.id,
        card: attr,
        status: 'We are publishing your Tacto Card. This may take up to a minute...'
      });
      return ref.id;
    }).catch(({ code, message }) => dispatch({
      type: types.CARD_CREATE_ERROR,
      code,
      message
    }));
};

export const copyLink = (link) => (dispatch, _, { logEvent }) => {
  dispatch({ type: types.CARD_LINK_COPY_START });
  logEvent('copy_link')
  return copy(link).then(() => dispatch({
    type: types.CARD_LINK_COPY_SUCCESS,
    status: 'Link copied to clipboard!'
  })).catch(() => dispatch({
    type: types.CARD_LINK_COPY_ERROR,
    status: `We couldn't copy your link. Here is is: ${link}`
  }));
}

export const fetch = (uid) => (dispatch, _, { db }) => {
  dispatch({ type: types.CARDS_FETCH_START });
  return db.collection('cards').where('uid', '==', uid).get()
    .then((querySnapshot) => dispatch({
      type: types.CARDS_FETCH_SUCCESS,
      card: querySnapshot.docs.length && querySnapshot.docs[0].data(),
      id: querySnapshot.docs.length && querySnapshot.docs[0].id
    })).catch(({ code, message }) => dispatch({
      type: types.CARDS_FETCH_ERROR,
      code,
      message
    }));
}

export const fetchStats = (cardId) => (dispatch, _, { functions }) => {
  dispatch({ type: types.CARD_FETCH_STATS_START });
  return functions.httpsCallable('getCardStats')({ id: cardId })
    .then(({ data }) => dispatch({
      type: types.CARD_FETCH_STATS_SUCCESS,
      stats: data
    })).catch(({ code, message }) => dispatch({
      type: types.CARD_FETCH_STATS_ERROR,
      code,
      message
    }));
}

export const update = (data) => (dispatch, getState, { db, logEvent }) => {
  dispatch({ type: types.CARD_UPDATE_START });
  logEvent('update_card');
  const card = db.collection('cards').doc(getState().card.id);
  if (!card) {
    return dispatch({
      type: types.CARD_UPDATE_ERROR, 
      message: 'Your card no longer exists.' 
    });
  }
  return card.update(data)
    .then(() => card.get())
    .then((latestCard) => {
      dispatch({
        type: types.CARD_UPDATE_SUCCESS,
        card: latestCard.data(),
        status: 'We are publishing your changes. This may take up to a minute...'
      })
      return card.id;
    }).catch(({ code, message }) => dispatch({
      type: types.CARD_UPDATE_ERROR,
      code,
      message
    }));
}

export const createTemplatesAndLink = (cardId) => async (dispatch, _, { firebase, functions }) => {
  dispatch({ type: types.CARD_CREATE_TEMPLATES_START });
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  return functions.httpsCallable('createCardTemplatesAndLink')({ id: cardId, idToken })
    .then(() => dispatch({
      type: types.CARD_CREATE_TEMPLATES_SUCCESS,
      status: 'Your card was successfully published!'
    })).catch(({ code, message }) => dispatch({
      type: types.CARD_CREATE_TEMPLATES_ERROR,
      code,
      message: 'There was an error creating your Tacto card. Please check the data above and try again.'
    }));
}

export const updateTemplatesAndPasses = (cardId) => (dispatch, _, { functions }) => {
  dispatch({ type: types.CARD_UPDATE_TEMPLATES_START });
  return functions.httpsCallable('updateCardTemplatesAndPasses')({ id: cardId })
    .then(() => dispatch({
      type: types.CARD_UPDATE_TEMPLATES_SUCCESS,
      status: 'Your changes were successfully published!'
    })).catch(({ code, message }) => dispatch({
      type: types.CARD_UPDATE_TEMPLATES_ERROR,
      code,
      message: 'There was an error updating your Tacto card. Please check the data above and try again.'
    }));
};

export const createAndPublish = (attr) => (dispatch) => 
  dispatch(create(attr))
  .then((cardId) => dispatch(createTemplatesAndLink(cardId)))
  .then((action) => action.type === types.CARD_CREATE_TEMPLATES_SUCCESS ? (
     dispatch(fetch(attr.uid))
    ) : action
  );

export const updateAndPublish = (attr) => (dispatch) => 
  dispatch(update(attr))
  .then((cardId) => dispatch(updateTemplatesAndPasses(cardId)))
  .then((action) => action.type === types.CARD_UPDATE_TEMPLATES_SUCCESS ? (
     dispatch(fetch(attr.uid))
    ) : action
  );

// sets the update value of a template, which results in a notification on iOS
export const sendNotification = (uid, update) => (dispatch, _, { logEvent }) => {
  logEvent('send_update');
  dispatch({
    status: 'Sending your update...',
    type: types.CARD_SEND_NOTIFICATION_START
  });
  return dispatch(updateAndPublish({ uid, updates: update }))
    .then(() => dispatch({
      status: 'Your update has been sent.',
      type: types.CARD_SEND_NOTIFICATION_SUCCESS,
    }));
}

export const setPreview = (data) => ({
  type: types.CARD_SET_PREVIEW,
  preview: data
});

export const clearStatus = () => ({
  type: types.CARD_CLEAR_STATUS
});