export const PLANS_FETCH_START = 'PLANS_FETCH_START';
export const PLANS_FETCH_SUCCESS = 'PLANS_FETCH_SUCCESS';
export const PLANS_FETCH_ERROR = 'PLANS_FETCH_ERROR';

export const PLAN_FETCH_CURRENT_START = 'PLAN_FETCH_CURRENT_START';
export const PLAN_FETCH_CURRENT_SUCCESS = 'PLAN_FETCH_CURRENT_SUCCESS';
export const PLAN_FETCH_CURRENT_ERROR = 'PLAN_FETCH_CURRENT_ERROR';

export const PLAN_CREATE_SUBSCRIPTION_START = 'PLAN_CREATE_SUBSCRIPTION_START';
export const PLAN_CREATE_SUBSCRIPTION_SUCCESS = 'PLAN_CREATE_SUBSCRIPTION_SUCCESS';
export const PLAN_CREATE_SUBSCRIPTION_ERROR = 'PLAN_CREATE_SUBSCRIPTION_ERROR';

export const PLAN_CANCEL_SUBSCRIPTION_START = 'PLAN_CANCEL_SUBSCRIPTION_START';
export const PLAN_CANCEL_SUBSCRIPTION_SUCCESS = 'PLAN_CANCEL_SUBSCRIPTION_SUCCESS';
export const PLAN_CANCEL_SUBSCRIPTION_ERROR = 'PLAN_CANCEL_SUBSCRIPTION_ERROR';

export const PLAN_UPDATE_SUBSCRIPTION_START = 'PLAN_UPDATE_SUBSCRIPTION_START';
export const PLAN_UPDATE_SUBSCRIPTION_SUCCESS = 'PLAN_UPDATE_SUBSCRIPTION_SUCCESS';
export const PLAN_UPDATE_SUBSCRIPTION_ERROR = 'PLAN_UPDATE_SUBSCRIPTION_ERROR';

export const PLAN_FETCH_SUBSCRIPTION_START = 'PLAN_FETCH_SUBSCRIPTION_START';
export const PLAN_FETCH_SUBSCRIPTION_SUCCESS = 'PLAN_FETCH_SUBSCRIPTION_SUCCESS';
export const PLAN_FETCH_SUBSCRIPTION_ERROR = 'PLAN_FETCH_SUBSCRIPTION_ERROR';