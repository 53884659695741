import React, { useState } from 'react';
import { connect } from 'react-redux';

import Android from './Android';
import IOs from './iOs';

import androidIcon from '../../../icons/android.png';
import iosIcon from '../../../icons/ios.png';

import { container, modeActive, modeSwitch, icon, wrapper } from './index.module.css';

const Preview = ({ card }) => {
  const [mode, setMode] = useState('ios');
  
  return (
    <div className={wrapper}>
      <div className={modeSwitch}>
        <button
          className={mode === 'ios' ? modeActive : ''}
          onClick={() => setMode('ios')}>
            iOS
            <img alt='ios' className={icon} src={iosIcon} />
        </button>
        <button
          className={mode === 'android' ? modeActive : ''}
          onClick={() => setMode('android')}>
            Google
            <img alt='android' className={icon} src={androidIcon} />
         </button>
      </div>
      <div className={container}>
        { mode === 'ios' ?
          (<IOs card={card} />)
          :
          (<Android card={card} />)
        }
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    card: state.card.preview
  })
)(Preview);