import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Container from '../../components/Container';
import { Alert, Form, Input, Select, Submit } from '../../components/Form';
import WaveBg from '../../components/WaveBg';

import * as accountActions from '../../redux/account/actions';

import accountSchema from './accountSchema';

const JOB_FUNCTION_OPTIONS = [
  null,
  'Business Development',
  'Customer Support',
  'Human Resources',
  'Information Technology',
  'Marketing',
  'Operations',
  'Research & Development',
  'Sales'
];

const CreateAccount = ({ createAccount, error, user }) => {
  const history = useHistory();
  useEffect(() => {
    if (user && user.emailVerified) {
      history.push('/');
    } else if (user && !error) {
      history.push('/account/verify');
    }
  }, [error, history, user])
  return (
    <Container oneCol>
      <WaveBg />
      <Form
        initialValues={{
          name: '',
          email: '',
          jobFunction: '',
          password: '',
          confirmPassword: ''
        }}
        onSubmit={(values) => createAccount(values)}
        validationSchema={Yup.object().shape(accountSchema)}
        >
        <h3>Create account</h3>
        <Input name="name" label="Name" />
        <Input name="email" label="Email" type="email" />
        <Select name="jobFunction" label="Job Function">
          {JOB_FUNCTION_OPTIONS.map((name, i) =>
            <option name={name} key={i}>{ name }</option>
          )}
        </Select>
        <Input name="password" label="Create Password" type="password" />
        <Input name="confirmPassword" label="Verify Password" type="password" />
        {error && <Alert error>{error.message}</Alert>}
        <Container fluid vSpace>
          <Submit>Create Account</Submit>
        </Container>
        <Link to='/account/login'>Back</Link>
      </Form>
    </Container>
  );
}

export default connect(
  ({ account }) => ({ user: account.current.user, error: account.error }), 
  (dispatch) => ({
    createAccount: (values) => dispatch(accountActions.createAndVerify(values)),
  })
 )(CreateAccount);