import { combineReducers } from 'redux';
import * as types from './types';

import { GLOBAL_CLEAR_ERROR, GLOBAL_CLEAR_SUCCESS } from '../types';

const data = (state = null, { type, card }) => {
  switch (type) {
    case types.CARD_CREATE_SUCCESS:
    case types.CARDS_FETCH_SUCCESS:
      return card;
    default:
      return state;
  }
}

const id = (state = null, { type, id }) => {
  switch(type) {
    case types.CARD_CREATE_SUCCESS:
    case types.CARDS_FETCH_SUCCESS:
      return id;
    default:
      return state;
  }
}

const stats = (state = {}, { type, card, stats }) => {
  switch(type) {
    case types.CARDS_FETCH_SUCCESS:
      return { 
        installedCount: card.installedCount,
        uninstalledCount: card.uninstalledCount
      };
    case types.CARD_FETCH_STATS_SUCCESS:
      return { ...stats };
    default:
      return state;
  }
}

const isWorking = (state = false, { type }) => {
  switch(type) {
    case types.CARD_CREATE_START:
    case types.CARDS_FETCH_START:
    case types.CARD_UPDATE_START:
      return true;
    case types.CARD_CREATE_SUCCESS:
    case types.CARD_CREATE_ERROR:
    case types.CARD_UPDATE_SUCCESS:
    case types.CARD_UPDATE_ERROR:
    case types.CARDS_FETCH_SUCCESS:
    case types.CARDS_FETCH_ERROR:
      return false;
    default:
      return state;
  }
};

const error = (state = null, { code, message, type }) => {
  switch(type) {
    case types.CARD_CREATE_START:
    case types.CARD_UPDATE_START:
    case types.CARD_UPDATE_TEMPLATES_START:
    case types.CARD_CREATE_TEMPLATES_START:
    case types.CARD_UPDATE_SUCCESS:
    case types.CARD_CREATE_SUCCESS:
    case types.CARD_UPDATE_TEMPLATES_SUCCESS:
    case types.CARD_CREATE_TEMPLATES_SUCCESS:
    case GLOBAL_CLEAR_ERROR:
      return null;
    case types.CARD_CREATE_ERROR:
    case types.CARD_UPDATE_ERROR:
    case types.CARDS_FETCH_ERROR:
    case types.CARD_UPDATE_TEMPLATES_ERROR:
    case types.CARD_CREATE_TEMPLATES_ERROR:
      return { code, message };
    default:
      return state;
  }
}

const status = (state = null, { status, type }) => {
  switch(type) {
    case types.CARD_CREATE_START:
    case types.CARD_UPDATE_START:
    case types.CARD_CREATE_ERROR:
    case types.CARD_UPDATE_ERROR:
    case types.CARDS_FETCH_ERROR:
    case types.CARD_CLEAR_STATUS:
    case types.CARD_CREATE_TEMPLATES_ERROR:
    case types.CARD_UPDATE_TEMPLATES_ERROR:
    case GLOBAL_CLEAR_SUCCESS:
      return null;
    case types.CARD_CREATE_SUCCESS:
    case types.CARD_CREATE_TEMPLATES_SUCCESS:
    case types.CARD_UPDATE_SUCCESS:
    case types.CARD_UPDATE_TEMPLATES_SUCCESS:
    case types.CARD_LINK_COPY_SUCCESS:
    case types.CARD_LINK_COPY_ERROR:
      return status;
    default:
      return state;
  }
}

const notificationStatus = (state = null, { status, type }) => {
  switch(type) {
    case types.CARD_SEND_NOTIFICATION_START:
    case types.CARD_SEND_NOTIFICATION_SUCCESS:
      return status;
    case GLOBAL_CLEAR_SUCCESS:
      return null;
    default:
      return state;
  }
}

const preview = (state = {}, { type, preview }) =>
  type === types.CARD_SET_PREVIEW ? preview : state;


export default combineReducers({
  data,
  error,
  id,
  isWorking,
  notificationStatus,
  preview,
  stats,
  status
});