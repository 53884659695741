import React from 'react';
import { connect } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import Container from '../../../components/Container';
import Modal from '../../../components/Modal';
import { Alert, Form, Submit } from '../../../components/Form';

import * as planActions from '../../../redux/plans/actions';

const Subscribe = ({ customer, successMessage, updateSubscription, error }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    const cardElement = elements.getElement(CardElement);
    updateSubscription(stripe, cardElement, customer.stripeId);
  };

  return (
    <Modal 
      title="Update your payment method"
      description="Please enter your new credit card info below."
      open={true}
    >
      <Container fluid vSpace>
        <Form
          initialValues={{}}
          onSubmit={handleSubmit}
          >
          <CardElement />
          <Submit disabled={!stripe}>Update subscription</Submit>
          {successMessage && <Alert success>{successMessage}</Alert>}
          {error && <Alert error>{error.message}</Alert>}
        </Form>
      </Container>
    </Modal>
  )
}

export default connect(
  ({ plans }) => ({
    error: plans.error,
    successMessage: plans.successMessage,
  }),
  (dispatch) => ({ 
    updateSubscription: (stripe, cardElement, customerId) => dispatch(planActions.updateSubscription(stripe, cardElement, customerId))
  })
)(Subscribe);