import { combineReducers } from 'redux';
import * as types from './types';

import { GLOBAL_CLEAR_ERROR, GLOBAL_CLEAR_SUCCESS } from '../types';

const options = (state = [], { plans, type }) => {
  switch(type) {
    case types.PLANS_FETCH_SUCCESS:
      return [...plans];
    default:
      return state;
  }
};

const isWorking = (state = false, { type }) => {
  switch(type) {
    case types.PLANS_FETCH_START:
      return true;
    case types.PLANS_FETCH_SUCCESS:
    case types.PLANS_FETCH_ERROR:
      return false;
    default:
      return state;
  }
};

const current = (state = null, { current, type }) => {
  switch(type) {
    case types.PLAN_FETCH_CURRENT_SUCCESS:
      return current;
    default: 
      return state;
  }

}

const subscription = (state = null, { subscription, type }) => {
  switch(type) {
    case types.PLAN_FETCH_SUBSCRIPTION_SUCCESS:
      return subscription;
    default:
      return state;
  }
}

const successMessage = (state = null, { message, type }) => {
  switch(type) {
    case types.PLAN_CREATE_SUBSCRIPTION_START:
    case types.PLAN_UPDATE_SUBSCRIPTION_START:
    case types.PLAN_CANCEL_SUBSCRIPTION_START:
    case types.PLAN_UPDATE_SUBSCRIPTION_SUCCESS:
    case types.PLAN_CANCEL_SUBSCRIPTION_SUCCESS:
      return message;
    case types.PLAN_CREATE_SUBSCRIPTION_ERROR:
    case types.PLAN_CREATE_SUBSCRIPTION_SUCCESS:
    case types.PLAN_UPDATE_SUBSCRIPTION_ERROR:
    case types.PLAN_CANCEL_SUBSCRIPTION_ERROR:
    case GLOBAL_CLEAR_SUCCESS:
      return null;
    default:
      return state;
  }
}

const error = (state = null, { code, message, type }) => {
  switch(type) {
    case types.PLANS_FETCH_START:
    case types.PLANS_FETCH_SUCCESS:
    case types.PLAN_CREATE_SUBSCRIPTION_START:
    case types.PLAN_CREATE_SUBSCRIPTION_SUCCESS:
    case types.PLAN_UPDATE_SUBSCRIPTION_START:
    case types.PLAN_UPDATE_SUBSCRIPTION_SUCCESS:
    case GLOBAL_CLEAR_ERROR:
      return null;
    case types.PLANS_FETCH_ERROR:
    case types.PLAN_CREATE_SUBSCRIPTION_ERROR:
    case types.PLAN_UPDATE_SUBSCRIPTION_ERROR:
    case types.PLAN_CANCEL_SUBSCRIPTION_ERROR:
    case types.PLAN_FETCH_CURRENT_ERROR:
      return { code, message };
    default:
      return state;
  }
}

export default combineReducers({
  current,
  options,
  isWorking,
  error,
  successMessage,
  subscription
});