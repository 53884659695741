import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../firebase';
import { connect } from 'react-redux';

import Container from '../../components/Container';
import Modal from '../../components/Modal';
import { Column, Grid } from '../../components/Grid';
import CtaButton from '../../components/CtaButton';

import * as customerActions from '../../redux/customer/actions';

const TRIAL_CARDS = 10;

const TrialPrompt = ({ customer, fetchCustomer, isSubscribed, stats, trialDaysLeft, user }) => {
  const [isDismissed, setIsDismissed] = useState(false);
  useEffect(() => {
    user && fetchCustomer(user.uid);
  }, [fetchCustomer, user]);
  const firebase = useContext(FirebaseContext);

  const shouldPrompt = Boolean(customer && !isSubscribed && stats.installedCount >= TRIAL_CARDS);
  if (shouldPrompt) {
    firebase.analytics().logEvent('reached_trial_limit');
  }

  return (
    <Modal
      description="Hi! You've reached the maximum number of cards on your free trial. If you want to continue using Tacto, please upgrade now."
      title='Upgrade to continue'
      handleClose={() => setIsDismissed(true)}
      open={shouldPrompt && !isDismissed}
    >
      <p className='introText'>
        If you decide not to, you'll be able to keep using Tacto for {trialDaysLeft} more days; however, no one else will be able to add your card to their wallet.
      </p>
      <Container fluid vSpace>
        <Grid equal responsive>
          <Column>
            <CtaButton eventName="dismiss_trial_warning" inline onClick={() => setIsDismissed(true)}>No thanks</CtaButton>
          </Column>
          <Column>
            <CtaButton inline primary solid linkTo='/account/subscribe'>Upgrade Now</CtaButton>
          </Column>
        </Grid>
      </Container>
    </Modal>
  )
}

export default connect(
  ({ customer }) => ({
    customer: customer.current,
    isSubscribed: customer.isSubscribed,
    trialDaysLeft: customer.trialDaysLeft
  }),
  (dispatch) => ({
    fetchCustomer: (uid) => dispatch(customerActions.fetchCurrent(uid))
  }),
)(TrialPrompt);
