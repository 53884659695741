import React from 'react';

import Container from '../../../components/Container';
import { Card, CardContent } from '@material-ui/core';

import { iosPreview, iosCard } from './index.module.css';

const iOsPreview = ({ card }) => card && (
  <Container className={iosPreview}>
     <h4>Front</h4>
    <Card className={iosCard}>
      <CardContent>
        <Container fluid>
          {card.logo && <img alt='logo' src={card.logo} width={80} />}
          <div style={{ float: 'right' }}>
            {card.name && <label>name</label>}
            {card.name && <h4>{ card.name }</h4>}
          </div>
        </Container>
        <Container fluid >
          <div>
            {card.title && <label>Title</label>}
            {card.title && <h4>{ card.title }</h4>}
          </div>
          <div style={{ float: 'right' }}>
            {card.profilePhoto && <img alt='profile' src={card.profilePhoto} width={80} />}
          </div>
        </Container>

        {card.company && <label>COMPANY</label>}
        {card.company && <h4>{ card.company }</h4>}
        <label>More Contact Info</label>
        <h4>See back of the card.</h4>
      </CardContent>
    </Card>
     <h4>Back</h4>
    <Card className={iosCard}>
      <CardContent>
        {card.name && <h3>{ card.name }</h3>}
        {card.email && (<Container fluid>
          <label>Email</label>
          <a href={card.email}>{ card.email }</a>
        </Container>)}
        {card.phone && (<Container fluid>
          <label>Phone</label>
          <a href={card.phone}>{ card.phone }</a>
        </Container>)}
        {card.website && (<Container fluid>
          <label>Website</label>
          <a href={card.website}>{ card.website }</a>
        </Container>)}
        {card.linkedinUrl && (<Container fluid>
          <label>Linkedin</label>
          <a href={card.linkedinUrl}>{ card.linkedinUrl }</a>
        </Container>)}
        {card.twitterUrl && (<Container fluid>
          <label>Twitter</label>
          <a href={card.twitterUrl}>{ card.twitterUrl }</a>
        </Container>)}
        {card.pronouns && (<Container fluid>
          <label>Pronouns</label>
          <p>{ card.pronouns }</p>
        </Container>)}
        {card.instagramUrl && (<Container fluid>
          <label>Instagram</label>
          <a href={card.instagramUrl}>{card.instagramUrl}</a>
        </Container>)}
        <Container fluid>
          <label>Get your own Tacto Card</label>
          <a href='https://tactohq.com'>Try Tacto for free!</a>
        </Container>
      </CardContent>
    </Card>
  </Container>
);

export default iOsPreview;