import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TrialPrompt from './TrialPrompt';

import { Column, Grid } from '../../components/Grid';
import AddCard from '../../components/AddCard';
import Container from '../../components/Container';
import CtaButton from '../../components/CtaButton';
import StatCard from '../../components/StatCard';
import WaveBg from '../../components/WaveBg';
import { Alert } from '../../components/Form';

import * as cardActions from '../../redux/card/actions';

import editIcon from '../../icons/edit.svg';
import linkIcon from '../../icons/link';
import sendIcon from '../../icons/send';
import installedIcon from '../../icons/installed.svg';
import removedIcon from '../../icons/removed.svg';

import { container, column } from './index.module.css';

const Dashboard = ({ card, cardId, copyLink, clearStatus, fetchCard, fetchStats, stats, status, user }) => {
  useEffect(() => {
    user && fetchCard(user);
  }, [user, fetchCard]);
  useEffect(() => {
    cardId && fetchStats(cardId)
  }, [cardId, fetchStats])
  return (
    <>
      <WaveBg />
      <TrialPrompt user={user} stats={stats} />
      <Container className={container} vSpace>
        {status && <Alert handleDismiss={clearStatus} success>{ status }</Alert>}
        <h3>{card ? `Welcome back, ${user.displayName}!` : 'Welcome to Tacto!'}</h3>
        <Grid responsive>
          <Column className={column}>
            <Container fluid vSpace>
              <StatCard
                count={stats.installedCount}
                description="Cards Added"
                icon={installedIcon}
                />
              <StatCard
                count={stats.uninstalledCount}
                description="Cards Removed"
                icon={removedIcon}
                />
            </Container>
          </Column>
          <Column className={column}>
            <CtaButton linkTo="/card/edit" icon={editIcon} solid primary>
              { card ? 'Edit Card' : 'Create Card' }
            </CtaButton>
            <CtaButton
              disabled={!card}
              onClick={() => card && copyLink(card.adaptiveUrl)}
              icon={linkIcon(card ? undefined : '#B9B9B9')}
              solid 
              primary
              >
              Copy Link to Share
            </CtaButton>
            <CtaButton
              disabled={!card}
              linkTo="/update/send"
              icon={sendIcon(card ? undefined : '#B9B9B9')}
              primary>
                Send an Update
            </CtaButton>
            {card ? <AddCard link={card.adaptiveUrl} /> : null}
          </Column>
        </Grid>
      </Container>
    </>
  );
}

export default connect(
  ({ account, card, dashboardStatus }) => ({ 
    card: card.data, 
    cardId: card.id,
    stats: card.stats,
    status: dashboardStatus,
    user: account.current.user
  }),
  (dispatch) => ({ 
    clearStatus: () => dispatch(cardActions.clearStatus()),
    copyLink: (link) => dispatch(cardActions.copyLink(link)),
    fetchCard: ({ uid }) => dispatch(cardActions.fetch(uid)),
    fetchStats: (cardId) => dispatch(cardActions.fetchStats(cardId))
  })
)(Dashboard);