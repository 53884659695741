import * as types from './types';

export const fetchCurrent = (uid) => async (dispatch, _, { db }) => {
  dispatch({ type: types.CUSTOMER_FETCH_CURRENT_START });
  try {
    const customer = await db.collection('customers').doc(uid).get();
    return dispatch({
      type: types.CUSTOMER_FETCH_CURRENT_SUCCESS,
      customer: customer.data()
    });
  } catch ({ code, message }) {
    return dispatch({
      type: types.CUSTOMER_FETCH_CURRENT_ERROR,
      code,
      message
    });
  }
}