import React from 'react';
import { connect } from 'formik';

import CtaButton from '../CtaButton';

import { submit } from './index.module.css';

const Submit = ({ children, formik: { isValid }, ...props }) => (
  <CtaButton
    className={submit}
    data-testid="form-submit-button"
    disabled={!isValid}
    type="submit"
    eventName="submit_form"
    primary
    solid
    {...props}
    >
    { children }
  </CtaButton>
);

export default connect(Submit);