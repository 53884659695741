import React from 'react';

import Container from '../../../components/Container';
import { Card, CardContent } from '@material-ui/core';

import logo from '../../../icons/tacto.png';
import { androidPreview, androidCard, androidFront, androidLogo } from './index.module.css';

export default ({ card }) => (
  <Container className={androidPreview}>
    <Card className={`${androidCard} ${androidFront}`}>
      <CardContent>
        {<div 
          className={androidLogo}
          style={{ backgroundImage: `url(${card.logo || logo})` }}>
        </div> }
        <Container fluid vSpace>
          {card.name && <h5>{ card.name }</h5>}
          {card.title && <h4>{ card.title }</h4>}
        </Container>
        {card.profilePhoto && (
          <img alt="profile" src={card.profilePhoto} width={80} />
        )}
        {card.company && (
          <Container fluid>
            <label>Company</label>
            <p>{ card.company }</p>
          </Container>
        )}
      </CardContent>
    </Card>
    <Card className={androidCard}>
      <CardContent>
        {card.pronouns && <Container fluid>
          <label>Pronouns</label>
          <h5>{ card.pronouns }</h5>
        </Container>}
        {card.phone && <Container fluid vSpace>
          { card.phone }
        </Container>}
        {card.email && <Container fluid vSpace>
          { card.email }
        </Container>}
        {card.website && <Container fluid vSpace>
          <a href={card.website}>Website</a>
        </Container>}
        {card.linkedinUrl && <Container fluid vSpace>
          <a href={card.linkedinUrl}>LinkedIn</a>
        </Container>}
        {card.twitterUrl && <Container fluid vSpace>
          <a href={card.twitterUrl}>Twitter</a>
        </Container>}
        {card.instagramUrl && <Container fluid vSpace>
          <a href={card.instagramUrl}>Instagram</a>
        </Container>}
        <Container fluid vSpace>
          <a href='https://tactohq.com'>Get your own Tacto card!</a>
        </Container>
      </CardContent>
    </Card>
  </Container>
);