import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Alert, FileUpload, Form, Input, Reset, Submit } from '../../components/Form';
import Container from '../../components/Container';

import * as cardActions from '../../redux/card/actions';
import * as profileActions from '../../redux/profile/actions';
import cardSchema from './cardSchema';
import ButtonGroup from '../../components/ButtonGroup';
import CreateSuccess from './CreateSuccess';
import UpdateSuccess from './UpdateSuccess';

const UpdateForm = ({ 
  card,
  createCard,
  error,
  fetchCard,
  fetchProfile,
  isWorking,
  profile,
  updateCard,
  setPreview,
  status,
  user
}) => {
  const history = useHistory();
  const formik = useRef();
  const unblock = useRef();
  useEffect(() => {
    fetchCard(user);
    fetchProfile(user);
  }, [fetchCard, fetchProfile, user]);

  const handleChange = (values) => {
    const { initialValues } = formik.current;
    const hasChanged = Object.keys(values).filter((k) => values[k] !== initialValues[k]).length > 0;
    if (hasChanged) {
      unblock.current = history.block('Are you sure you want to discard your changes?');
    } else {
      unblock.current && unblock.current();
    }
    setPreview(values)
  }

  useEffect(() => {
    return () => unblock.current && unblock.current();
  });
  
  const handleSubmit = (values) => {
    const attr = { uid: user.uid, ...values };
    const isUpdate = card && card.iosTemplateId && card.androidTemplateId;
    const action = isUpdate ? updateCard : createCard;
    action(attr).then((action) => {
      if (action.type.match(/SUCCESS$/)) {
        if (isUpdate) {
          history.push(profile.declinedUpdatePrompt ? '/' : '/card/edit/updated', { preserveStatus: true })
        } else {
          history.push('/card/edit/created');
        }
      }
    });
  }
  return (
    <Container>
      <Form
        initialValues={{
          name: user.displayName,
          pronouns: '',
          company: '',
          title: '',
          email: user.email,
          phone: '',
          website: '',
          linkedinUrl: '',
          twitterUrl: '',
          instagramUrl: '',
          ...card
        }}
        enableReinitialize
        innerRef={formik}
        onChange={handleChange}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape(cardSchema)}
        >
        <h3>Edit card</h3>
        <Input name='name' label='Name' />
        <Input name='pronouns' label='Pronouns' />
        <Input name='company' label='Company' />
        <Input name='title' label='Title' />
        <FileUpload
          destination={`userUploads/${user.uid}/`}
          name='profilePhoto'
          label='Photo of You' 
          />
        <FileUpload
          destination={`userUploads/${user.uid}/`}
          name='logo'
          label='Your company logo' 
          />
        <h3>Contact</h3>
        <Input name='email' label='Email' type='email' placeholder='hello@tactohq.com' />
        <Input name='phone' label='Phone Number' placeholder='123.456.7890' />
        <Input name='website' label='Website' placeholder="https://tactohq.com" />
        <h3>Connect</h3>
        <Input name='linkedinUrl' label='LinkedIn URL' placeholder='https://linkedin.com/in/tactohq' />
        <Input name='twitterUrl' label='Twitter URL' placeholder='https://twitter.com/tactohq' />
        <Input name='instagramUrl' label='Instagram URL' placeholder='https://instagram.com/tactohq' />
        { error && <Alert error>{ error.message }</Alert>}
        { status && <Alert success>{ status }</Alert>}
        <ButtonGroup>
          <Reset inline>Discard Changes</Reset>
          <Submit disabled={isWorking} inline>Publish</Submit>
        </ButtonGroup>
        <CreateSuccess />
        <UpdateSuccess />
      </Form>
    </Container>
  )
}

export default connect(
  ({ account, card, profile }) => ({ 
    card: card.data,
    cardId: card.id,
    error: card.error,
    profile: profile.data,
    status: card.status,
    isWorking: card.isWorking,
    user: account.current.user
  }),
  (dispatch) => ({
    fetchCard: (user) => dispatch(cardActions.fetch(user.uid)),
    fetchProfile: (user) => dispatch(profileActions.fetch(user.uid)),
    createCard: (attr) => dispatch(cardActions.createAndPublish(attr)),
    setPreview: (attr) => dispatch(cardActions.setPreview(attr)),
    updateCard: (attr) =>  dispatch(cardActions.updateAndPublish(attr))
  })
)(UpdateForm);