import React from 'react';
import { useFormikContext } from 'formik';
import CtaButton from '../CtaButton';

const ResetButton = ({ children, ...props }) => {
  const { resetForm } = useFormikContext();
  return (
    <CtaButton {...props} eventName='reset_form' onClick={resetForm}>
      {children}
    </CtaButton>
  );
}

export default ResetButton;