import { combineReducers } from 'redux';
import * as types from './types';

const TRIAL_DAYS = 60;
const MS_PER_DAY = 24 * 60 * 60 * 1000;

const current = (state = null, { type, customer }) => {
  switch(type) {
    case types.CUSTOMER_FETCH_CURRENT_SUCCESS:
      return customer;
    default: 
      return state;
  }
};

const isSubscribed = (state = null, { type, customer }) => {
  switch(type) {
    case types.CUSTOMER_FETCH_CURRENT_SUCCESS:
      return ['paid', 'enterprise', 'active'].includes(customer.subscriptionStatus);
    default: 
      return state;
  }

}

const trialDaysLeft = (state = null, { type, customer }) => {
  if (type === types.CUSTOMER_FETCH_CURRENT_SUCCESS) {
    let daysLeft = 0;
    if (customer?.trialStart) {
      const timeLeft = TRIAL_DAYS * MS_PER_DAY - (Date.now() - customer.trialStart);
      daysLeft = Math.max(0, Math.floor(timeLeft / MS_PER_DAY));
    }
    return daysLeft;
  } else {
    return state;
  }
}

export default combineReducers({ 
  current,
  isSubscribed,
  trialDaysLeft
})