import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import CtaButton from '../../../components/CtaButton';
import { Alert, Checkbox, Input, Form, Submit } from '../../../components/Form';
import Container from '../../../components/Container';
import SubscriptionStatus from './SubscriptionStatus';

import accountSchema from '../accountSchema';
import * as accountActions from '../../../redux/account/actions';
import * as profileActions from '../../../redux/profile/actions';


const Settings = ({ changePassword, error, fetchProfile, logout, profile, success, updateProfile, user }) => {
  useEffect(() => {
    fetchProfile(user);
  }, [fetchProfile, user]);

  const handleSubmit = async ({ currentPassword, declinedUpdatePrompt, password }) => {
    await updateProfile(user, { declinedUpdatePrompt });
    if (password && password.length > 0) {
      await changePassword(currentPassword, password);
    }
  };

  const formik = useRef(null);
  const validationSchema = () => {
    const { confirmPassword, password } = accountSchema;
    return formik.current.values.password ? Yup.object().shape({
        currentPassword: Yup.string().required('Your current password is required.'),
        confirmPassword,
        password 
      }) : Yup.object();
  }
  return (
    <Container oneCol>
      <Form
        initialValues={{
          confirmPassword: '',
          currentPassword: '',
          declinedUpdatePrompt: Boolean(profile.declinedUpdatePrompt),
          password: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formik}
        >
        <h3>Profile</h3>
        <h4>{user.email}</h4>
        <Input name='currentPassword' label='Current Password' type='password' />
        <Input name='password' label='New Password' type='password' />
        <Input name='confirmPassword' label='Confirm New Password' type='password' />
        <SubscriptionStatus user={user} />
        <h3>Notifications</h3>
        <Checkbox name='declinedUpdatePrompt' label='Never ask me if I want to send updates to my contacts.'/>
        {error && <Alert error>{error.message}</Alert>}
        {success && <Alert success>{success}</Alert>}
        <Submit>Change settings</Submit>
        <CtaButton onClick={logout}>Log Out</CtaButton>
      </Form>
    </Container>
  );
}

export default connect(
  ({ account, globalError, globalSuccess, profile }) => ({ 
    user: account.current.user,
    error: globalError,
    success: globalSuccess,
    profile: profile.data
  }),
  (dispatch) => ({ 
    changePassword: (currentPassword, newPassword) => dispatch(accountActions.updatePassword(currentPassword, newPassword)),
    fetchProfile: (user) => dispatch(profileActions.fetch(user.uid)),
    updateProfile: (user, data) => dispatch(profileActions.addData(user.uid, data)),
    logout: () => dispatch(accountActions.logout()) 
  })
)(Settings);