import React from 'react';

import Container from '../Container';
import { Field, ErrorMessage } from 'formik';
import { Input } from '@material-ui/core';

import { fieldError, input, inputWrap } from './index.module.css';

export default ({ multiline, name, label, placeholder, type, ...props }) => (
  <Container fluid vSpace>
    {label && <label htmlFor={name}>{label}</label>}
    <Field name={name} {...props}>
      {({ field }) => (
        <Input
          classes={{ root: inputWrap, input }}
          id={name}
          label={label}
          multiline={multiline}
          type={type}
          placeholder={placeholder}
          {...field} 
        />
      )}
    </Field>
      <ErrorMessage name={name} render={(msg) => (
        <div className={fieldError} data-testid='field-error'>
          {msg}
        </div>
      )}/>
  </Container>
)