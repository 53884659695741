import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@material-ui/core';

import { iconStyle, wrapper } from './index.module.css';

const StatCard = ({
  count='-',
  description='',
  icon=null
}) => (
  <Card className={wrapper}>
    <h1 title={description}>
      { typeof count === 'undefined' ? '-' : count }
    </h1>
    <p className='cardText'>{ description }</p>
    {icon && <img alt='description' className={iconStyle} src={icon} />}
  </Card>


);

StatCard.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  icon: PropTypes.string
};

export default StatCard;