import React from 'react';
import { Link } from 'react-router-dom';

import { Form } from '../../components/Form';
import CtaButton from '../../components/CtaButton';
import Container from '../../components/Container';

const AccountSuccess = () => (
  <Container oneCol>
    <Form>
      <h3>Account verified</h3>
      <p className='introText'>
        Great! Now let's set up your subscription. 
      </p>
      <Container fluid vSpace>
        <CtaButton linkTo='/account/subscribe' solid primary>Enter payment info</CtaButton>
      </Container>
      <Link to='/account/subscribe/trialInfo'>Not sure? Continue with free trial</Link>
    </Form>
  </Container>
);

export default AccountSuccess;