import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';

import closeIcon from '../../icons/close.svg';

import { closeButton, modal } from './index.module.css';

const Modal = ({ children, description, handleClose, history, open, parentPath, title }) => {
  const onCloseClicked = () => {
    if (handleClose) {
      handleClose();
    } else {
      parentPath ? history.push(parentPath) : history.goBack()
    }
  }
  return (
    <Dialog 
      classes={{ paper: modal }}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      open={open} >
      <DialogContent>
        <h4 id='modal-title'>{title}</h4>
        <IconButton className={closeButton} onClick={onCloseClicked}>
          <img alt='close modal' src={closeIcon} />
        </IconButton>
        <p className='introText' id='modal-description'>{description}</p>
        { children }
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(Modal);

