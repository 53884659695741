import * as types from './types';

export const fetchAll = () => async (dispatch, _, { db }) => {
  dispatch({ type: types.PLANS_FETCH_START });
  try {
    const plans = await db.collection('plans')
      .where('active', '==', true)
      .get()
      .then((snap) => Promise.all(snap.docs.map(async (doc) => {
        const planData = doc.data();
        const prices = await doc.ref.collection('prices').get()
        return {
          ...planData,
          id: doc.id,
          prices: prices.docs.map((p) => ({ ...p.data(), id: p.id }))
        };
      })));
    return dispatch({ type: types.PLANS_FETCH_SUCCESS, plans })
  } catch ({ code, message }) {
    dispatch({
      type: types.PLANS_FETCH_ERROR, 
      code,
      message
    });
  }
}

export const fetchStripeSubscription = (subscriptionId) => async(dispatch, _, { getStripe }) => {
  dispatch({
    type: types.PLAN_FETCH_SUBSCRIPTION_START
  });
  try {
    const stripe = await getStripe();
    const subscription = await stripe.subscriptions.retrieve(subscriptionId);
    return dispatch({
      type: types.PLAN_FETCH_SUBSCRIPTION_SUCCESS,
      subscription
    })
  } catch ({ code, message}) {
    return dispatch({
      type: types.PLAN_FETCH_SUBSCRIPTION_ERROR,
      code,
      message
    });
  }
}

export const cancelSubscription = (subscriptionId) => async( dispatch, _, { firebase, functions, logEvent }) => {
  logEvent('cancel_subscription');
  dispatch({ 
    type: types.PLAN_CANCEL_SUBSCRIPTION_START,
    message: 'Canceling your subscription. Please wait....'
  });
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    await functions.httpsCallable('cancelSubscription')({ idToken, subscriptionId });
    return dispatch({
      type: types.PLAN_CANCEL_SUBSCRIPTION_SUCCESS,
      message: 'Your subscription has been canceled.'
    });
  } catch ({ code, message }) {
    dispatch({
      type: types.PLAN_CREATE_SUBSCRIPTION_ERROR,
      code,
      message: 'There was an issue canceling your account. Please contact Tacto support.'
    });
    throw new Error(message);
  }
}

export const updateSubscription = (stripe, cardElement, customerId) => async(dispatch, _, { firebase, functions, logEvent }) => {
  logEvent('update_payment_method', { method: 'credit_card' });
  dispatch({ 
    type: types.PLAN_UPDATE_SUBSCRIPTION_START,
    message: 'Updating your subscription. Please wait....'
  });
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement
  });
  if (error) {
    return dispatch({
      type: types.PLAN_UPDATE_SUBSCRIPTION_ERROR,
      code: error.code,
      message: error.message
    });
  }
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    await functions.httpsCallable('updateSubscription')({ 
      customerId,
      idToken,
      paymentMethodId: paymentMethod.id
    });
    return dispatch({
      type: types.PLAN_UPDATE_SUBSCRIPTION_SUCCESS,
      message: 'Your payment method has been updated.'
    });
  } catch ({ code, message }) {
    return dispatch({
      type: types.PLAN_UPDATE_SUBSCRIPTION_ERROR,
      code,
      message
    });
  }
}

export const createSubscription = (stripe, cardElement, priceId) => 
  async (dispatch, _, { db, firebase, functions, logEvent }) => {
    const { uid } = firebase.auth().currentUser;
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    logEvent('subscribe', { method: 'credit_card', priceId })
    dispatch({
      type: types.PLAN_CREATE_SUBSCRIPTION_START, 
      message: 'Please wait while we set up your subscription...'
    });
    const customer = await db.collection('customers').doc(uid).get();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });
    if (error) {
      dispatch({
        type: types.PLAN_CREATE_SUBSCRIPTION_ERROR,
        code: error.code,
        message: error.message
      });
      throw error;
    } else {
      try {
        const { data } = await functions.httpsCallable('createSubscription')({
          customerId: customer.data().stripeId,
          idToken,
          paymentMethodId: paymentMethod.id,
          priceId
        })
        return dispatch({
          type: types.PLAN_CREATE_SUBSCRIPTION_SUCCESS,
          subscriptionId: data.id
        });
      } catch ({ code, message }) {
        dispatch({
          type: types.PLAN_CREATE_SUBSCRIPTION_ERROR,
          code: code,
          message: message
        });
        throw Error(message);
      }
    }
  };

export const fetchCurrent = (uid) => async (dispatch, _, { db, firebase }) => {
  dispatch({ type: types.PLAN_FETCH_CURRENT_START });
  try {
    const ref = await db.collection('customers')
      .doc(uid)
      .get();
    let plan = null;
    const customer = ref && ref.data();
    if (customer && customer.productId) {
      const planRef = await db.collection('plans')
        .doc(customer.productId)
        .get();
      if (planRef && customer.subscriptionStatus === 'active') {
        plan = planRef.data();
      }
    }
    return dispatch({
      type: types.PLAN_FETCH_CURRENT_SUCCESS,
      current: plan
    })
  } catch ({ code, message }) {
    throw dispatch({
      type: types.PLAN_FETCH_CURRENT_ERROR,
      code,
      message
    });
  }
}