import React from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';

import Container from '../../../components/Container';
import CtaButton from '../../../components/CtaButton';
import { Column, Grid } from '../../../components/Grid';
import Modal from '../../../components/Modal';
import { Alert } from '../../../components/Form';
import * as planActions from '../../../redux/plans/actions';

const CancelSubscription = ({ cancelSubscription, customer, error, onSuccess, successMessage }) => {
  const history = useHistory();
  const handleCancel = async () => {
    try {
      await cancelSubscription(customer.subscriptionId);
      onSuccess();
      history.push('/account/settings');
    } catch (e) {}
  }
  return (
    <Modal 
      title="Are you sure?"
      description="By cancelling this subscription, your card will expire from all devices where it was added, and your account will revert back to a free trial."
      open={true}
    >
      <Container fluid vSpace>
        <Grid equal responsive>
          <Column>
            <CtaButton onClick={handleCancel} inline primary solid>Yes, Cancel</CtaButton>
          </Column>
          <Column>
            <CtaButton eventName="abort_cancel_subscription" linkTo="/account/settings" inline>No, Go Back</CtaButton>
          </Column>
        </Grid>
        {successMessage && <Alert success>{successMessage}</Alert>}
        {error && <Alert error>{error.message}</Alert>}
      </Container>
    </Modal>
  )
}

export default connect(
  ({ plans }) => ({
    error: plans.error,
    successMessage: plans.successMessage,
  }),
  (dispatch) => ({
    cancelSubscription: (subscriptionId) => dispatch(planActions.cancelSubscription(subscriptionId))
  })
  )(CancelSubscription);