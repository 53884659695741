import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';

import { Button } from '@material-ui/core';

import { button, buttonIcon, buttonInline, buttonPrimary, buttonSolid } from './index.module.css';

const CtaButton = ({
  children,
  inline = false,
  disabled = false,
  linkTo,
  href,
  icon,
  onClick,
  primary,
  solid,
  eventName = '',
  ...props
}) => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  let eventSlug = eventName || linkTo || href;
  eventSlug = eventSlug?.replace(/[^A-Za-z]/g, '_');
  const handleClick = (evt) => {
    if (eventSlug) {
      firebase.analytics().logEvent(`click_${eventSlug}`);
    }
    if (linkTo) {
      history.push(linkTo)
    }
    if (onClick) {
      return onClick(evt);
    }
  }

  return (
    <Button 
      classes={{
        root: `${button} ${solid ? buttonSolid : ''} ${primary ? buttonPrimary : ''} ${inline ? buttonInline : ''}`,
        endIcon: buttonIcon,
        label: 'ctaText'
      }}
      component={href ? 'a' : 'button'}
      disabled={disabled}
      endIcon={icon && (React.isValidElement(icon) ? icon : <img alt='icon' src={icon} />) }
      href={href}
      onClick={handleClick}
      {...props}
      >
      { children }
    </Button>
  );
}

CtaButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  eventName: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  primary: PropTypes.bool,
  solid: PropTypes.bool
}

export default CtaButton;