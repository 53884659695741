import React, { useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { form } from './index.module.css';

export { default as Alert } from './Alert';
export { default as Checkbox } from './Checkbox';
export { default as FileUpload } from './FileUpload';
export { default as Input } from './Input';
export { default as Submit } from './Submit';
export { default as Select } from './Select';
export { default as Reset } from './Reset';

export const Form = ({ children, initialValues, onChange, enableReinitialize=true, ...props }) => {
  useEffect(() => {
    onChange && onChange(initialValues)
  }, [initialValues, onChange]);
  return (
  <Formik
    initialValues={initialValues}
    enableReinitialize={enableReinitialize}
    {...props}
    >
    {({ values }) => (
      <div className={form}>
        <FormikForm onChange={(evt) => onChange && onChange({ ...values, [evt.target.name]: evt.target.value })}>
          {children}
        </FormikForm>
      </div>
    )}
  </Formik>
  );
};

export default Form;