import React from 'react';
import PropTypes from 'prop-types';

import { container, containerFluid, containerOneCol, containerVSpace } from './index.module.css';

const Container = ({ children, className, fluid = false, vSpace = false, oneCol = false, ...props }) => (
  <div className={`${container} ${fluid ? containerFluid : ''} ${vSpace ? containerVSpace : ''} ${oneCol ? containerOneCol : ''} ${className || ''}`} {...props}>
    { children }
  </div>
);

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  vSpace: PropTypes.bool
}

export default Container;