import * as Yup from  'yup';

export default {
  name: Yup.string()
    .required('Required.'),
  email: Yup.string()
    .required('Required.')
    .email('Invalid email'),
  jobFunction: Yup.string().required('Required.'),
  password: Yup.lazy((value) => {
    if (!value) {
      return Yup.string().required('Required.');
    } else if (value.length > 15) {
      return Yup.string().max(64);
    } else {
      return Yup.string().matches(
        /^(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must contain 8 characters, one number and one special character, or be 15 characters long.'
      );
    }
  }),
  confirmPassword: Yup.string()
      .required('Required.')
      .equals([Yup.ref('password')], 'Passwords must match.')
}