import * as types from './types';
import { addData as addProfileData } from '../profile/actions';

export const create = ({ name, email, jobFunction, password }) => (dispatch, _, { logEvent, firebase }) => {
  dispatch({ type: types.ACCOUNT_CREATE_START });
  return firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(({ user }) => {
      dispatch({
        type: types.ACCOUNT_CREATE_SUCCESS,
        user
      });
      logEvent('sign_up', { method: 'email', jobFunction })
      return user.updateProfile({ displayName: name })
        .then(() => dispatch(addProfileData(user.uid, {
          name,
          email,
          jobFunction,
          declinedUpdatePrompt: true
        })));
    }).catch(({ code, message }) => dispatch({
      type: types.ACCOUNT_CREATE_ERROR,
      code,
      message
    }))
};

export const confirmVerificationCode = (oobCode) => (dispatch, _, { firebase, logEvent }) => {
  dispatch({ type: types.ACCOUNT_CONFIRM_VERIFICATION_START });
  return firebase.auth().applyActionCode(oobCode)
    .then(() => {
      logEvent('verify_email');
      return dispatch({
        type: types.ACCOUNT_CONFIRM_VERIFICATION_SUCCESS,
      });
    }).then(() => dispatch(reload()))
    .catch(({ code, message }) => dispatch({
      type: types.ACCOUNT_CONFIRM_VERIFICATION_ERROR,
      code,
      message
    }));
}

export const sendPasswordResetCode = (email) => (dispatch, _, { firebase, logEvent }) => {
  dispatch({ type: types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_START });
  logEvent('send_password_reset');
  return firebase.auth().sendPasswordResetEmail(email).then(() => dispatch({
    type: types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_SUCCESS,
    message: `Your reset code was successfully sent. Please check your inbox ${email} for a link to continue.`
  })).catch(({ code, message }) => dispatch({
    type: types.ACCOUNT_SEND_PASSWORD_RESET_EMAIL_ERROR,
    code,
    message
  }));
}

export const verifyPasswordResetCode = (oobCode) => (dispatch, _, { firebase }) => {
  dispatch({ type: types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_START });
  try {
    return firebase.auth().verifyPasswordResetCode(oobCode)
      .then((email) => dispatch({
        type: types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_SUCCESS,
        email
      })).catch(({ code, message }) => dispatch({
        type: types.ACCOUNT_VERIFY_PASSWORD_RESET_CODE_ERROR,
        code,
        message
      }));
  } catch ({ code, message }) {
    dispatch({
      type: types.ACCOUNT_UPDATE_PASSWORD_ERROR,
      code,
      message
    });
  }
};

//update while already authenticated
export const updatePassword = (currentPassword, password) => (dispatch, _, { firebase, logEvent }) => {
  dispatch({ type: types.ACCOUNT_UPDATE_PASSWORD_START });
  logEvent('update_password');
  const user = firebase.auth().currentUser;
  return firebase.auth().signInWithEmailAndPassword(user.email, currentPassword)
    .then(() => user.updatePassword(password))
    .then(() => dispatch({
      type: types.ACCOUNT_UPDATE_PASSWORD_SUCCESS,
      message: 'Your password was successfully updated.'
    }))
    .catch(({ code, message }) => dispatch({
      type: types.ACCOUNT_UPDATE_PASSWORD_ERROR,
      code,
      message
    }));
}

//reset from unauthenticated state using email code
export const resetPassword = (oobCode, newPassword) => (dispatch, _, { firebase, logEvent }) => {
  dispatch({ type: types.ACCOUNT_RESET_PASSWORD_START });
  logEvent('reset_password');
  return firebase.auth().confirmPasswordReset(oobCode, newPassword)
    .then(() => dispatch({
      type: types.ACCOUNT_RESET_PASSWORD_SUCCESS,
      message: 'Your account password was successfully reset.'
    })).catch(({ code, message }) => dispatch({
      type: types.ACCOUNT_RESET_PASSWORD_ERROR,
      code,
      message
    }));
};

export const sendVerificationEmail = () => (dispatch, _, { firebase, logEvent }) => {
  dispatch({ type: types.ACCOUNT_SEND_VERIFICATION_START });
  logEvent('send_verification_email');
  const user = firebase.auth().currentUser;
  return user.sendEmailVerification()
    .then(() => dispatch({
      type: types.ACCOUNT_SEND_VERIFICATION_SUCCESS,
      message: `We sent a verification link to your email, ${user.email}`
    })).catch(({ code, message }) => dispatch({
      type: types.ACCOUNT_SEND_VERIFICATION_ERROR,
      code,
      message
    }));
};

export const createAndVerify = (values) => (dispatch) => {
  return dispatch(create(values))
    .then(({ type }) => type.match(/SUCCESS$/) && dispatch(sendVerificationEmail()));
}

export const login = ({ email, password }) => (dispatch, _, { firebase, logEvent }) => {
  dispatch({ type: types.ACCOUNT_LOGIN_START });
  logEvent('login')
  return firebase.auth().signInWithEmailAndPassword(email, password).then(({ user }) => dispatch({
    type: types.ACCOUNT_LOGIN_SUCCESS,
    user
  })).catch(({ code, message }) => dispatch({
    type: types.ACCOUNT_LOGIN_ERROR,
    code,
    message
  }));
}

export const logout = () => (dispatch, _, { firebase, logEvent }) => {
  dispatch({ type: types.ACCOUNT_LOGOUT_START });
  logEvent('logout');
  return firebase.auth().signOut().then(() => dispatch({
    type: types.ACCOUNT_LOGOUT_SUCCESS
  })).catch(({ code, message }) => dispatch({
    type: types.ACCOUNT_LOGOUT_ERROR,
    code,
    message
  }));
};

export const reload = () => (dispatch, _, { firebase }) => {
  const user = firebase.auth().currentUser;
  if (!user) { return }
  dispatch({ type: types.ACCOUNT_RELOAD_START });
  return user.reload().then(() => dispatch({
    type: types.ACCOUNT_RELOAD_SUCCESS,
    user: firebase.auth().currentUser
  })).catch(({ code, message }) => dispatch({
    type: types.ACCOUNT_RELOAD_ERROR,
    code,
    message
  }));
}

export const hydrate = (user) => ({
  type: user ? types.ACCOUNT_HYDRATE_LOGGED_IN : types.ACCOUNT_HYDRATE_LOGGED_OUT,
  user
});