import React from 'react';

import { contact, wrapper } from './index.module.css';

const BottomNav = () => (
  <div className={wrapper}>
      <span>
        Powered by <a href="https://www.airship.com/platform/channels/mobile-wallet/" rel="noopener noreferrer" target="_blank">Airship</a>
      </span>
      <span className={contact}>
        <a href="https://tactohq.com/contact" rel="noopener noreferrer" target="_blank">Contact Us</a>
      </span>
  </div>

)

export default BottomNav;