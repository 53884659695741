import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { connect } from 'react-redux';
import * as customerActions from '../../../redux/customer/actions';

import CancelSubscription from './CancelSubscription';
import UpdatePayment from './UpdatePayment';
import CtaButton from '../../../components/CtaButton';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
const SubscriptionStatus = ({ customer, fetchCustomer, trialDaysLeft, isSubscribed, user }) => {
  const isUpdateOpen = useRouteMatch('/account/settings/updateSubscription');
  const isCancelOpen = useRouteMatch('/account/settings/cancelSubscription');
  useEffect(() => {
    user && fetchCustomer(user.uid);
  }, [fetchCustomer, user]);
  const renewalDate = customer && new Date(customer.nextRenewal);
  return (
    <>
      { isSubscribed && customer.paymentCard ? (
        <>
          <h3>Subscription</h3>
          <p>
              Your subscription is active. It will automatically renew on {renewalDate.toLocaleString('default', { month: 'long' })} {renewalDate.getDate()}, {renewalDate.getFullYear()}
              &nbsp;using {capitalize(customer.paymentCard?.brand)} ... {customer.paymentCard?.last4}.
          </p>
          <p>
            <CtaButton primary linkTo='/account/settings/updateSubscription'>Update Payment</CtaButton>
            <CtaButton linkTo='/account/settings/cancelSubscription'>Cancel Subscription</CtaButton>
          </p>
        </>
      ) : !isSubscribed && trialDaysLeft !== null && (
        <>
          <h3>Subscription</h3>
          <p>You have {trialDaysLeft} days left in your trial.</p>
          <CtaButton primary linkTo='/account/subscribe'>Upgrade now</CtaButton>
        </>
      )}
      {isUpdateOpen && <UpdatePayment customer={customer} />}
      {isCancelOpen && <CancelSubscription customer={customer} onSuccess={() => fetchCustomer(user.uid)} />
      }
    </>
  )
}

export default connect(
  ({ customer }) => ({
    customer: customer.current,
    isSubscribed: customer.isSubscribed,
    trialDaysLeft: customer.trialDaysLeft,
  }),
  (dispatch) => ({
    fetchCustomer: (uid) => dispatch(customerActions.fetchCurrent(uid)),
  })
)(SubscriptionStatus);