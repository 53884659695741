export const CARD_CREATE_START = 'CARD_CREATE_START';
export const CARD_CREATE_SUCCESS = 'CARD_CREATE_SUCCESS';
export const CARD_CREATE_ERROR = 'CARD_CREATE_ERROR';

export const CARD_UPDATE_START = 'CARD_UPDATE_START';
export const CARD_UPDATE_SUCCESS = 'CARD_UPDATE_SUCCESS';
export const CARD_UPDATE_ERROR = 'CARD_UPDATE_ERROR';

export const CARDS_FETCH_START = 'CARDS_FETCH_START';
export const CARDS_FETCH_SUCCESS = 'CARDS_FETCH_SUCCESS';
export const CARDS_FETCH_ERROR = 'CARDS_FETCH_ERROR';

export const CARD_SET_PREVIEW = 'CARD_SET_PREVIEW';

export const CARD_LISTEN_START = 'CARD_LISTEN_START';
export const CARD_LISTEN_SUCCESS = 'CARD_LISTEN_SUCCESS';

export const CARD_CREATE_TEMPLATES_START = 'CARD_CREATE_TEMPLATES_START';
export const CARD_CREATE_TEMPLATES_SUCCESS = 'CARD_CREATE_TEMPLATES_SUCCESS';
export const CARD_CREATE_TEMPLATES_ERROR = 'CARD_CREATE_TEMPLATES_ERROR';

export const CARD_UPDATE_TEMPLATES_START = 'CARD_UPDATE_TEMPLATES_START';
export const CARD_UPDATE_TEMPLATES_SUCCESS = 'CARD_UPDATE_TEMPLATES_SUCCESS';
export const CARD_UPDATE_TEMPLATES_ERROR = 'CARD_UPDATE_TEMPLATES_ERROR';

export const CARD_LINK_COPY_START = 'CARD_LINK_COPY_START';
export const CARD_LINK_COPY_SUCCESS = 'CARD_LINK_COPY_SUCCESS';
export const CARD_LINK_COPY_ERROR = 'CARD_LINK_COPY_ERROR';

export const CARD_FETCH_STATS_START = 'CARD_FETCH_STATS_START';
export const CARD_FETCH_STATS_SUCCESS = 'CARD_FETCH_STATS_SUCCESS';
export const CARD_FETCH_STATS_ERROR = 'CARD_FETCH_STATS_ERROR';

export const CARD_CLEAR_STATUS = 'CARD_CLEAR_STATUS';

export const CARD_SEND_NOTIFICATION_START = 'CARD_SEND_NOTIFICATION_START'
export const CARD_SEND_NOTIFICATION_SUCCESS = 'CARD_SEND_NOTIFICATION_SUCCESS'