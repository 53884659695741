import React from 'react';
import { connect } from 'react-redux';

import Container from '../../components/Container';
import CtaButton from '../../components/CtaButton';
import Modal from '../../components/Modal';
import { Grid, Column } from '../../components/Grid';

const Subscribed = ({ card, isWorking }) => (
  <Modal
    title='Subscription active!'
    description="Thanks for signing up."
    open={true}
    parentPath='/'
  >
    <Container fluid vSpace>
    { !isWorking && (
      <>
        {!card && <p className='introText'>Now, let's go ahead and create your card. It should only take a few minutes.</p>}
          {card ? (
            <CtaButton linkTo='/' primary solid>Go to Dashboard</CtaButton>
          ) : (
            <Grid equal responsive>
                <Column>
                  <CtaButton inline linkTo='/card/edit' primary solid>Create Card</CtaButton>
                </Column>
              <Column>
                <CtaButton inline linkTo='/'>I'll do this later</CtaButton>
              </Column>
            </Grid>
          )}
      </>
    )}
    </Container>
  </Modal>
);

export default connect(
  ({ card }) => ({ card: card.data, isWorking: card.isWorking })
)(Subscribed);