import React from 'react';
import { Alert as MuiAlert } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';

import closeIcon from '../../icons/close.svg';

import { alert, closeAlert } from './index.module.css';

const Alert = ({ children, error, success, info, handleDismiss, warning, ...props }) => {
  const args = { error, success, info, warning };
  const severity = Object.keys(args).filter((k) => args[k]).join('') || 'info';
  return (
    <MuiAlert className={`${alert} ${severity}`} data-testid='form-alert' icon={false} severity={severity} {...props}>
      { children }
      { handleDismiss && <IconButton className={closeAlert} onClick={handleDismiss}>
        <img alt='Dismiss' src={closeIcon} />
      </IconButton>}
     </MuiAlert>
  );
}

export default Alert;