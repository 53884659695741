import React from 'react';

export default (fillColor='#7835FA') => (
    <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>send-24px</title>
        <g id="desktop-wireframes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Dashboard---Desktop" transform="translate(-876.000000, -332.000000)" fill={fillColor} fillRule="nonzero">
                <g id="Group-3" transform="translate(643.000000, 313.000000)">
                    <g id="send-24px" transform="translate(233.000000, 19.000000)">
                        <polygon id="Shape" points="0.00761904762 13.6 16 6.8 0.00761904762 0 0 5.28888889 11.4285714 6.8 0 8.31111111"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)