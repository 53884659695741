import React from 'react';

import isMobile from 'is-mobile';
import QRCode from 'qrcode';
import CtaButton from '../CtaButton';

import { qrCode, qrWrap } from './index.module.css';
import icon from '../../icons/addCard.svg';

const AddCard = ({ link }) => {
  if (!link) {
    return null;
  }
  return isMobile({ tablet: true }) ? (
      <CtaButton icon={icon} href={link} primary>Add card to my wallet</CtaButton>
    ) : (
      <div className={qrWrap}>
        <canvas className={qrCode}
          title='Scan to add card to my wallet'
          height={100}
          ref={(el) => el && QRCode.toCanvas(el, link, { scale: 3 })}
          width={100} 
          />
        <p>Scan to add card to my wallet</p>
      </div>
    );
}

export default AddCard;