import { combineReducers } from 'redux';
import * as types from './types';

const data = (state = {}, { profile, type }) => {
  switch(type) {
    case types.PROFILE_ADD_SUCCESS:
      return { ...state, ...profile };
    case types.PROFILE_FETCH_SUCCESS:
      return { ...profile };
    default:
      return state;
  }
}

const error = (state = null, { code, message, type }) => {
  switch(type) {
    case types.PROFILE_ADD_START:
    case types.PROFILE_FETCH_START:
      return null;
    case types.PROFILE_ADD_ERROR:
    case types.PROFILE_FETCH_ERROR:
      return { code, message }
    default:
      return state;
  }
}

export default combineReducers({
  data,
  error
});