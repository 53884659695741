import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearGlobalError, clearGlobalSuccess } from '../redux/actions';

const ResetMessages = ({ dispatch }) => {
  const history = useHistory();
  history.listen((location) => {
    const preserveStatus = location.state && location.state.preserveStatus;
    if (!preserveStatus) {
      dispatch(clearGlobalError());
      dispatch(clearGlobalSuccess());
    }
  });
  return null;
}

export default connect()(ResetMessages)