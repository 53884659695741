import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ButtonGroup from '../../components/ButtonGroup';
import Container from '../../components/Container';
import CtaButton from '../../components/CtaButton'; import Modal from '../../components/Modal';

const TrialInfo = () => {
  const match = useRouteMatch('/account/subscribe/trialInfo');
  return (
    <Modal
      open={Boolean(match)}
      title='Not ready to sign up?'
      description='If you’re not ready to sign up today, that’s okay! 
        We’ll keep your account active on a free trial so you can try it out in the meantime. 
        On a free trial, you can create and share your card with up to 10 people before you’ll be asked to subscribe.'
    >
      <Container fluid vSpace>
        <ButtonGroup>
          <CtaButton eventName="continue_trial" linkTo='/'>Continue with trial</CtaButton>
          <CtaButton eventName="continue_subscribe" linkTo='/account/subscribe' primary solid>Go back to sign up</CtaButton>
        </ButtonGroup>
      </Container>
    </Modal>

  )
}

export default TrialInfo