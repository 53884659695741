import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import { Alert, Form, Input, Submit} from '../../components/Form';
import WaveBg from '../../components/WaveBg';

import * as accountActions from '../../redux/account/actions';

const Login = ({ error, login, successMessage, user }) => {
  const history = useHistory();
  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [ history, user])
  return (
    <Container oneCol>
      <WaveBg />
      <Form
        initialValues={{ email: '', password: '' }}
        onSubmit={({ email, password }) => login({ email, password })}
        >
        <h3>Login</h3>
        <Input name="email" label="Email" />
        <Input name="password" label="password" type="password" />
        {error && <Alert error>{error.message}</Alert>}
        {successMessage && <Alert success>{ successMessage }</Alert> }
        <Submit>Login</Submit>
        <p><Link to="/account/create">Create an Account</Link></p>
        <p><Link to="/account/forgot">Forgot password?</Link></p>
      </Form>
    </Container>
  );
}

export default connect(
  ({ account }) => ({ user: account.current.user, successMessage: account.successMessage, error: account.error }), 
  (dispatch) => ({
    login: (values) => dispatch(accountActions.login(values))
  })
 )(Login);