import { combineReducers } from 'redux';

import account from './account';
import card from './card';
import customer from './customer';
import profile from './profile';
import plans from './plans';

import * as cardTypes from './card/types';
import * as types from './types';

const globalError = (state = null, { code, message, type }) => {
  if (type === types.GLOBAL_CLEAR_ERROR){
    return null;
  } else if (type.match(/ERROR$/)) {
    return { code, message };
  } else if (type.match(/START$/)) {
    return null;
  } else {
    return state;
  }
}

const globalSuccess = (state = null, { message, type }) => {
  if (type === types.GLOBAL_CLEAR_SUCCESS){
    return null;
  } else if (type.match(/SUCCESS$/) && message) {
    return message;
  } else if (type.match(/START$/)) {
    return null;
  } else {
    return state;
  }
}

const dashboardStatus = (state = null, { status, type }) => {
  switch (type) {
    case types.GLOBAL_CLEAR_SUCCESS:
    case cardTypes.CARD_CLEAR_STATUS:
      return null;
    case cardTypes.CARD_UPDATE_TEMPLATES_SUCCESS:
    case cardTypes.CARD_CREATE_TEMPLATES_SUCCESS:
    case cardTypes.CARD_SEND_NOTIFICATION_SUCCESS:
    case cardTypes.CARD_LINK_COPY_SUCCESS:
      return status;
    default:
      return state;
  }
}

export default combineReducers({
  account,
  card,
  customer,
  dashboardStatus,
  globalError,
  globalSuccess,
  plans,
  profile
});