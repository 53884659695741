import React from 'react';
import queryString from 'query-string'
import { Redirect } from 'react-router';

import ResetPassword from './ResetPassword';
import ConfirmEmail from './ConfirmEmail';
import WaveBg from '../../../components/WaveBg';

const AccountActions = () => {
  const qs = queryString.parse(window.location.search);
  if (qs.mode === 'resetPassword') {
    return <ResetPassword oobCode={qs.oobCode} />
  } else if (qs.mode === 'verifyEmail') {
    return <ConfirmEmail oobCode={qs.oobCode} />
  } else {
    return <Redirect to='/' />
  }
}

export default () => (<>
  <WaveBg />
  <AccountActions />
</>
);