import React from 'react';
import { connect } from 'react-redux';
import { Alert, Form, Input, Submit } from '../../components/Form';
import Container from '../../components/Container';
import WaveBg from '../../components/WaveBg';

import * as accountActions from '../../redux/account/actions';

const ForgotPassword = ({ error, successMessage, sendPasswordResetCode }) => (
  <>
    <WaveBg />
    <Container oneCol>
      <Form initialValues={{ email: '' }} onSubmit={({ email }) => sendPasswordResetCode(email)}>
        <h3>Use this form to request a password reset link.</h3>
        <Input name='email' label='Email' />
        {error && <Alert error>{ error.message }</Alert>}
        {successMessage && <Alert success>{ successMessage }</Alert>}
        <Submit>Reset my password</Submit>
      </Form>
    </Container>
  </>
);

export default connect(
  (state) => ({ error: state.account.error, successMessage: state.account.successMessage }),
  (dispatch) => ({ sendPasswordResetCode: (email) => dispatch(accountActions.sendPasswordResetCode(email)) })
)(ForgotPassword);