import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Container from '../../components/Container';
import { Alert, Form, Submit } from '../../components/Form';
import * as planActions from '../../redux/plans/actions';
import CtaButton from '../../components/CtaButton';
import TrialInfo from './TrialInfo';

import { pricingTable, vSpace } from '../../App.module.css'

const PRICE_ID = process.env.REACT_APP_ENV === 'dev' ?
'price_1HR31fHIVpwP8AemdXkgndQF' :
'price_1HKQBmHIVpwP8AemT0irB0tJ';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const Subscribe = ({ current, createSubscription, error, fetchCurrent, fetchPlans, isWorking, plans, successMessage, user }) => {
  useEffect(() => {
    fetchCurrent(user.uid).then(() => {
      fetchPlans();
    });
  }, [fetchCurrent, fetchPlans, user])
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const renewalDate = new Date(Date.now() + 30 * MS_PER_DAY);

  const handleSubmit = async () => {
    const cardElement = elements.getElement(CardElement);
    createSubscription(stripe, cardElement, PRICE_ID).then(() => {
      history.push('/account/subscribed');
    }).catch(({ message }) => console.error(message));
  };

  return (
    <Container oneCol>
      <h3>
        Review Subscription
      </h3>
      <p>
        Your subscription will automatically renew each month.
        The charge will vary depending on how many of your cards
        are added to people’s wallets, so you only pay for what 
        you use. Cancel at any time.
      </p>
      <table className={pricingTable}>
        <thead>
          <tr>
            <th>Cards Added</th>
            <th>Monthly Charge</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>0 - 100</td><td>$1.29</td></tr>
          <tr><td>101 - 200</td><td>$2.25</td></tr>
          <tr><td>201 - 500</td><td>$4.79</td></tr>
          <tr><td>501 - 1,000</td><td>$8.29</td></tr>
          <tr><td>1,001 - 2,000</td><td>$10.69</td></tr>
          <tr><td>2,001 +</td><td>$0.0054 per card</td></tr>
        </tbody>
      </table>
      <Form
        initialValues={{}}
        onSubmit={handleSubmit}
        >
        Please enter your payment information below to sign up. 
        All information is transmitted securely through our payment processor, Stripe.

        <div className={vSpace}>
          <CardElement />
        </div>
        <p className='finePrint'>
          By subscribing you agree to our <a href='https://tactohq.com/terms-of-service/' rel='noopener noreferrer' target='blank'>Terms of Service</a> and <a href='https://tactohq.com/privacy-policy' rel='noopener noreferrer' target='_blank'>Privacy Policy</a>. 
          Your next charge will be
          on {renewalDate.toLocaleString('default', { month: 'long' })} {renewalDate.getDate()}, {renewalDate.getFullYear()}
          . You can cancel your subscription 
          from your profile settings at any time or by contacting our support team.
        </p>
        <Submit disabled={!stripe}>Confirm and sign up</Submit>
        <CtaButton linkTo='/account/subscribe/trialInfo' primary>Continue with trial</CtaButton>
        {successMessage && <Alert success>{successMessage}</Alert>}
        {error && <Alert error>{error.message}</Alert>}
      </Form>
      <TrialInfo />
    </Container>
  )
}

export default connect(
  ({ account, plans }) => ({
    current: plans.current,
    error: plans.error,
    isWorking: plans.isWorking,
    plans: plans.options,
    successMessage: plans.successMessage ,
    user: account.current.user
  }),
  (dispatch) => ({ 
    fetchCurrent: (uid) => dispatch(planActions.fetchCurrent(uid)),
    fetchPlans: () => dispatch(planActions.fetchAll()),
    createSubscription: (...args) => dispatch(planActions.createSubscription(...args))
  })
)(Subscribe);