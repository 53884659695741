import * as types from './types';

export const addData = (uid, data) => (dispatch, _, { db, logEvent }) => {
  dispatch({ type: types.PROFILE_ADD_START });
  logEvent('update_profile', data);
  return db.collection('profiles').doc(uid).set(data, { merge: true })
    .then(() => dispatch({
      type: types.PROFILE_ADD_SUCCESS,
      profile: data,
      message: 'Your profile has been updated.'
    })).catch(({ code, message }) => ({
      type: types.PROFILE_ADD_ERROR,
      code,
      message
    }));
}

export const fetch = (uid) => (dispatch, _, { db }) => {
  dispatch({ type: types.PROFILE_FETCH_START });
  return db.collection('profiles').doc(uid).get()
    .then((profile) => dispatch({
      type: types.PROFILE_FETCH_SUCCESS,
      profile: profile.data()
    })).catch(({ code, message }) => dispatch({
      type: types.PROFILE_FETCH_ERROR,
      code,
      message
    }));
}
