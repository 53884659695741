import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import accountSchema from '../accountSchema';
import * as accountActions from '../../../redux/account/actions';

import Container from '../../../components/Container';
import { Alert, Form, Input, Submit } from '../../../components/Form';

const ResetPassword = ({ error, oobCode, email, resetPassword, successMessage, verifyPasswordResetCode }) => {
  const history = useHistory();
  useEffect(() => {
    verifyPasswordResetCode(oobCode);
  }, [oobCode, verifyPasswordResetCode])
  if (email) {
    return (
      <Container oneCol>
        <Form 
          initialValues={{ password: '', confirmPassword: '' }}
          onSubmit={({ password }) => {
            resetPassword(oobCode, password).then(() => history.push('/account/login')) 
          }}
          validationSchema={Yup.object().shape({
            password: accountSchema.password,
            confirmPassword: accountSchema.confirmPassword 
          })}
          >
            <h3>Please set a new password for your account, { email }</h3>
            <Input name='password' label='Password' type='password' />
            <Input name='confirmPassword' label='Confirm Password' type='password' />
            {error && <Alert error>{ error.message }</Alert> }
            {successMessage && <Alert success>{ successMessage }</Alert> }
            <Submit>Set Password</Submit>
        </Form>
      </Container>
    );
  } else if (error) {
    return (
      <Container oneCol>
        <Alert error>{ error.message }</Alert>
      </Container>
    );
  } else {
    return (
      <Container oneCol>
        <h3>Verifying your reset code...</h3>
      </Container>
    );
  };
};

export default connect(
  (state) => ({ 
    error: state.account.error,
    successMessage: state.account.successMessage,
    email: state.account.resetEmail
  }),
  (dispatch) => ({
    resetPassword: (oobCode, password) => dispatch(accountActions.resetPassword(oobCode, password)),
    verifyPasswordResetCode: (oobCode) => dispatch(accountActions.verifyPasswordResetCode(oobCode))
  })
 )(ResetPassword);