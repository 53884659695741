import React from 'react';

import Container from '../Container';
import { Field, ErrorMessage } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { checkbox, checkboxLabel } from './index.module.css';

export default ({ name, label, placeholder, type, ...props }) => (
  <Container fluid vSpace>
    <Field name={name} {...props}>
      {({ field }) => (
        <FormControlLabel
          classes={{ root: checkbox, label: checkboxLabel }}
          label={label}
          control={
            <Checkbox
              checked={field.value}
              {...field} 
            />
          }
        />
      )}
    </Field>
    <ErrorMessage name={name} />
  </Container>
)