import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Alert, Form } from '../../components/Form';
import Container from '../../components/Container';
import CtaButton from '../../components/CtaButton';
import WaveBg from '../../components/WaveBg';

import * as accountActions from '../../redux/account/actions';

const VerifyAccount = ({
    credential,
    error,
    user,
    reload,
    sendVerificationEmail,
    successMessage
  }) => {
  const history = useHistory();
  useEffect(() => {
    if (!user) {
      history.push('/account/login');
    } else if (user.emailVerified) {
      history.push('/account/success');
    }
    const refresh = setInterval(() => reload(user), 5000);
    return () => clearInterval(refresh);
  }, [credential, history, reload, user]);

  return (
    <Container oneCol>
      <WaveBg />
      <Form>
        <h3>Verify your account</h3>
        <p className="introText">We just sent an email with a link to verify your account. 
        Check your email and click the link to confirm your account.</p>
        {successMessage && <Alert success>{ successMessage }</Alert>}
        {error && <Alert error>{ error.message }</Alert>}
        <CtaButton primary onClick={() => sendVerificationEmail(user)}>Resend Link</CtaButton>
      </Form>
    </Container>
  );
};

export default connect(
  ({ account }) => ({ 
    error: account.error,
    successMessage: account.successMessage,
    user: account.current.user
  }), 
  (dispatch) => ({
    reload: (user) => dispatch(accountActions.reload(user)),
    sendVerificationEmail: (user) => dispatch(accountActions.sendVerificationEmail(user))
  })
)(VerifyAccount);