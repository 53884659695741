import React from 'react';

import { Grid, Column } from '../../components/Grid';
import Container from '../../components/Container';

import UpdateForm from './UpdateForm';
import Preview from './Preview';

import { preview } from './Update.module.css';

const UpdateCard = () => (
  <Container>
    <Grid equal responsive>
      <Column className={preview}>
        <Preview />
      </Column>
      <Column>
        <UpdateForm />
      </Column>
    </Grid>
  </Container>
)

export default UpdateCard;