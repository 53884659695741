import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { AppBar, IconButton } from '@material-ui/core';

import Container from '../Container';

import accountSettings from '../../icons/account-settings.svg';
import logo from '../../icons/logo.svg';

import { container, wrapper, wrapperEdit } from './index.module.css';

const TopNav = () => {
  const match = useRouteMatch('/card/edit');
  return (
    <AppBar className={`${wrapper} ${match ? wrapperEdit : ''}`} position="static">
      <Container className={container}>
        <IconButton><Link to='/'><img alt="Tacto Home" src={logo} /></Link></IconButton>
        <IconButton><Link to='/account/settings'><img alt='Account Settings' src={accountSettings} /></Link></IconButton>
      </Container>
    </AppBar>
  );
}

export default TopNav;