import React from 'react';
import { Select as MuiSelect } from '@material-ui/core';

import Container from '../Container';
import { Field, ErrorMessage } from 'formik';

import { fieldError, select } from './index.module.css';

const Select = ({ children, name, label,  ...props}) => (
  <Container fluid vSpace>
    {label && <label htmlFor={name}>{label}</label>}
    <Field name={name} {...props}>
      {({ field }) => (
        <MuiSelect className={select} labelId={name} id={name} name={name} native {...field}>
          { children }
        </MuiSelect>
      )}
    </Field>
    <ErrorMessage name={name} render={(msg) => (
      <div className={fieldError} data-testid='field-error'>
        {msg}
      </div>
    )}/>
  </Container>
);

export default Select;