import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import rootReducer from './redux/reducers';

import firebase, { config, FirebaseContext } from './firebase';

import { hydrate as hydrateAccount } from './redux/account/actions';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './styles/index.css';

firebase.initializeApp(config);


const analytics = firebase.analytics();
const db = firebase.firestore();
const functions = firebase.functions();
if (window.location.hostname === "localhost" && window.location.search.indexOf('emulate') !== -1) {
  db.settings({
    host: "localhost:8080",
    ssl: false
  });
}
if (window.location.hostname === "localhost") {
  functions.useFunctionsEmulator('http://localhost:5001');
}

const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === 'dev' ?
  'pk_test_51HKBljHIVpwP8Aem9NyX954rCuVHCVMoIthInNZxYFVGLQ1x3SBGTRAh9BOugELbzePy7DL06adYjlSaFyies78j00mg1DmGj9' :
  'pk_live_51HKBljHIVpwP8AemfZV6qTOTpqbQZ7QlU2sjNuzkwuZ9cvn7SsRkxZLImB5iyH9mZ4C7re2uR080jBWqMsQLbqcN005Fs4N1oF'
);


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk.withExtraArgument({ 
  logEvent: analytics.logEvent, db, firebase, functions, getStripe: () => stripePromise
}))));

const supportLocalStorage = typeof window.localStorage !== undefined;
supportLocalStorage && store.dispatch(hydrateAccount(JSON.parse(localStorage.getItem('userAccount'))));

firebase.auth().onAuthStateChanged((user) => {
  analytics.setUserId(user.uid);
  store.dispatch(hydrateAccount(user));
  supportLocalStorage && localStorage.setItem('userAccount', JSON.stringify(user));
});

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={firebase}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
);
