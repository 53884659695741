import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from '../../../components/Form';
import Container from '../../../components/Container';

import * as accountActions from '../../../redux/account/actions';

const ConfirmEmail = ({ error, oobCode, confirmVerificationCode }) => {
  const history = useHistory();
  useEffect(() => {
    confirmVerificationCode(oobCode)
      .then(() => history.push('/account/verify'));
  }, [confirmVerificationCode, history, oobCode])
  return (
    <Container oneCol>
      {error && <Alert error>{ error.message }</Alert>}
    </Container>
  );
}


export default connect(
  (state) => ({ error: state.account.error }),
  (dispatch) => ({
    confirmVerificationCode: (oobCode) => dispatch(accountActions.confirmVerificationCode(oobCode)),
  })
)(ConfirmEmail);