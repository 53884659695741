import * as Yup from  'yup';

const urlError = 'Not a valid URL. Make sure to include the http prefix';

export default {
  name: Yup.string()
    .required('Required.'),
  email: Yup.string()
    .email('Invalid email'),
  phone: Yup.string()
    .matches(
      /((?:\+|00)[17](?: |[.-])?|(?:\+|00)[1-9]\d{0,2}(?: |[.-])?|(?:\+|00)1[.-]\d{3}(?: |[.-])?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |[.-])[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |[.-])[0-9]{3}(?: |[.-])[0-9]{4})|([0-9]{7}))/,
      'Please enter your phone number, including country code if applicable.'
  ),
  website: Yup.string().url(urlError),
  linkedinUrl: Yup.string()
    .url(urlError).matches(
      /^https:\/\/(www\.)?linkedin.com\//,
      'Starts with https://linkedin.com/'
    ),
  twitterUrl: Yup.string().url(urlError).matches(
    /^https:\/\/(www\.)?twitter.com\//,
    'Starts with https://twitter.com/'
  ),
  instagramUrl: Yup.string().url(urlError).matches(
    /^https:\/\/(www\.)?instagram.com\//,
    'Starts with https://instagram.com/'
  )
}