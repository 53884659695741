import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import Container from '../../components/Container';
import Modal from '../../components/Modal';
import ButtonGroup from '../../components/ButtonGroup';
import CtaButton from '../../components/CtaButton';

import * as profileActions from '../../redux/profile/actions';


const UpdateSuccess = ({ declinePrompt, user }) => {
  const match = useRouteMatch('/card/edit/updated');
  const history = useHistory();
  return (
    <Modal
      description='Do you want to send a notification about this update to your iOS contacts?'
      title='Card published!'
      open={Boolean(match)}
    >
      <ButtonGroup>
        <CtaButton eventName="cancel_send_update" inline linkTo='/'>Not this time</CtaButton>
        <CtaButton inline primary solid linkTo='/update/send'>Yes, send update</CtaButton>
      </ButtonGroup>
      <Container vSpace style={{ textAlign: 'center' }}>
        <a href='#decline' onClick={() => declinePrompt(user.uid).then(() => history.push('/'))}>
          Never ask me this again
        </a>
      </Container>
    </Modal>
  )
}

export default connect(
  ({ account }) => ({ user: account.current.user }),
  (dispatch) => ({ 
    declinePrompt: (uid) => dispatch(profileActions.addData(uid, { declinedUpdatePrompt: true }))
  })
)(UpdateSuccess);
