import React from 'react';

import styles from './index.module.css';

export const Column = ({ children, className = '', ...props }) => (
  <div className={`${styles.column} ${className}`} {...props}>
    { children }
  </div>
);

export const Grid = ({ children, responsive = false, equal = false }) => (
  <div className={`${styles.grid} ${responsive ? styles.responsive : ''} ${equal ? styles.equal : ''}`}>
    { children }
  </div>
);